import React from "react";
import Header from "../../components/Header/HeaderView";
import EduNewsView from "../../components/EduNews/EduNewsView";
import HomeRegistationHelp from "../../components/HomeRegistationHelp";
import HomeStoreHelp from "../../components/HomeStoreHelp";
import Footer from "../../components/Footer/FooterView";
import HomeMagazineHelp from "../../components/HomeMagazineHelp";


const HomeView:React.FC=()=>
{


return(
<>
<Header/>
<EduNewsView/>
{/* <HomeRegistationHelp/> */}
<HomeStoreHelp/>
<HomeMagazineHelp/>
<Footer/>
</>
);


}


export default HomeView;