import { useEffect, useState } from "react";
import { SchoolRepository } from "../../repository/SchoolRepository";
import { useNavigate } from 'react-router-dom';
import { ActionType } from "../../store/Page.Actions";
import { useDispatch } from "react-redux";
export function StateListViewModel()
{
    const dispatch = useDispatch();
	const navigate = useNavigate();
    const repository =SchoolRepository();
const [lstStates,setStates]=useState([]);
const stateClick=(state:any)=>
{

    dispatch({
        type: ActionType.STORE,
        payload: {
            storeData:{state_id:state.state_id,state_name:state.state_name},
            viewName:'SelectedState',
        },
      });
      navigate('/districtlist');

}
useEffect(() => {
    
    const fetchAllOptions = async () => {
      try {
          debugger ;
          
        const data:any = await repository.viewStates();
debugger ;
      
        
setStates(data);
       
      } catch (error) {
        console.error('Error fetching all options:', error);
      }
    };

    fetchAllOptions();
  }, []); 
  return{lstStates,stateClick};
}
