import { EduNewsViewModel } from "./EduNewsViewModel";
import "../../components/carousel.css";

import { Swiper, SwiperSlide } from "swiper/react";
// import { EffectCoverflow, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/effect-coverflow";
import 'swiper/css/pagination';
import SwiperCore, { Autoplay, EffectCoverflow, Pagination } from 'swiper';
SwiperCore.use([Autoplay, EffectCoverflow, Pagination]);

const EduNewsView: React.FC = () => {

    // const slide_img = [
    //     "https://swiperjs.com/demos/images/nature-1.jpg",
    //     "https://swiperjs.com/demos/images/nature-2.jpg",
    //     "https://swiperjs.com/demos/images/nature-3.jpg",
    //     "https://swiperjs.com/demos/images/nature-4.jpg",
    //     "https://swiperjs.com/demos/images/nature-5.jpg",
    //     "https://swiperjs.com/demos/images/nature-6.jpg",
    //     "https://swiperjs.com/demos/images/nature-7.jpg",
    //     "https://swiperjs.com/demos/images/nature-8.jpg",
    //     "https://swiperjs.com/demos/images/nature-9.jpg",
    // ];


    const viewMode = EduNewsViewModel();
    return (<section className="gallery py-5">
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                    <div className="main-swiper">
                        <Swiper
                            effect={"coverflow"}
                            grabCursor={true}
                            centeredSlides={true}
                            slidesPerView={2}
                            coverflowEffect={{
                                rotate: 50,
                                stretch: 0,
                                depth: 100,
                                modifier: 1,
                                slideShadows: true,
                            }}
                            modules={[EffectCoverflow, Pagination]}
                            className="swiper"
                            autoplay={{ delay: 2500 }}
                        >

                            {/* using Array */}
                            {viewMode.rssFeeds.map((image, i) => {
                                return (
                                    <SwiperSlide key={i}>
                                        <img src={image.enclosure["@attributes"].url} alt="" onClick={(e) => viewMode.openFeedPage(image.link)}/>
                                        <p className="text-center text-dark active-text m-0"><b>{image.title}</b></p>
                                    </SwiperSlide>);
                            })}
                        </Swiper>
                    </div>
                </div>
            </div>
            {/* <div className="row">
                <div className="col-12">
                    <h2 className="text-center mb-0 fw-bold">In the Headlines</h2>
                    <div className="carousel-container pr" >
                        <div className="gallery-carousel owl-carousel owl-theme">
                            {
                                viewMode.rssFeeds.map((item, i) => (
                                    <div key={i} onClick={(e) => viewMode.openFeedPage(item.link)}>
                                        <img src={item.enclosure["@attributes"].url} className="img-fluid carousel-img-auto" alt={item.title} loading="lazy" />
                                        <p className="text-center text-dark active-text m-0">{item.title}</p>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="align-ver-center">
                            <a className="carousel-control-prev prev-btn ml-50" data-slide="prev"  >
                                <i className="fa fa-angle-left"></i>
                            </a>
                            <a className="carousel-control-next next-btn mr-30" data-slide="next">
                                <i className="fa fa-angle-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-12 text-center"> <button className="eti-btn" onClick={viewMode.OpenEduNews}>View All</button></div>
            </div> */}
        </div>
    </section>);



}

export default EduNewsView;