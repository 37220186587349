// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './pages/Home/HomeView';
import SchoolRegistration from './pages/SchoolRegistration/SchoolRegistrationView';

import EduNewsView from './components/EduNews/EduNewsView';
import RegistrationSuccess from './pages/RegistrationSuccess/RegistrationSuccessView';
import EduNews from './pages/EduNewsList/EduNewsListView';
import Header from './components/Header/HeaderView';
import SchoolsList from './pages/SchoolsList/SchoolListView';
import SchoolDetails from './pages/SchoolDetails/SchoolDetailsView';
import StateListView from './pages/StatesList/StateListView';
import DistrictsListView from './pages/DistrictsList/DistrictsListView';
import CitiesListView from './pages/CitiesList/CitiesListView';
import ClustersListView from './pages/ClustersList/ClustersListView';
import AdmissionHelpHomeView from './pages/AdmissionHelpHome/AdmissionHelpHomeView';
import AdmissionSchoolSearch from './components/AdmissionSchoolSearch/AdmissionSchoolSearch';
import B2BHome from './components/B2BHome/B2BHome';
import KeyFeatures from './components/KeyFeatures/KeyFeatures';
import CaringEnvironment from './components/CaringEnvironment/CaringEnvironment';
import EducationalResources from './components/EducationalResources/EducationalResources';
import EducationTodayMagazine from './components/EducationTodayMagazine/EducationTodayMagazine';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import TermsOfService from './components/TermsOfService/TermsOfService';
import OurTeam from './components/OurTeam/OurTeam';
import EduEvents from './components/EduEvents/EduEvents';
import Subscribe from './components/Subscribe/Subscribe';
import Advertise from './components/Advertise/Advertise';
import Pricing from './components/Pricing/Pricing';
import PaymentGateway from './pages/PaymentGateway/PaymentGateway';
import OrderResult from './pages/OrderResult/OrderResult';
 


const App: React.FC = () => {
  return (
    
     <Router>
    <Routes>
      <Route path="/home" element={<Home/>} />
      <Route path="/" element={<Home/>} />
      <Route path="/schoolregistration" element={<SchoolRegistration/>} />
      <Route path="/schoolfeeds" element={<EduNewsView/>} />
      <Route path="/registrationsuccess" element={<RegistrationSuccess/>} />
      <Route path="/edunews" element={<EduNews/>} />

      <Route path="/head" element={<Header/>} />
      <Route path="/schools" element={<SchoolsList/>} />
      <Route path="/viewschool" element={<SchoolDetails/>} />
      <Route path="/:school_name" element={<SchoolDetails/>} />
      
      <Route path="/statelist" element={<StateListView/>} />
      <Route path="/districtlist" element={<DistrictsListView/>} />
      <Route path="/citieslist" element={<CitiesListView/>} />

      <Route path="/clusterslist" element={<ClustersListView/>} />
       
      <Route path="/admissionhelphome" element={<AdmissionHelpHomeView/>} />
      <Route path="/admission-school-search" element={<AdmissionSchoolSearch/>} />
      <Route path="/b2b-home" element={<B2BHome/>} />

      <Route path="/key-features" element={<KeyFeatures/>} />

      <Route path="/caring-environment" element={<CaringEnvironment/>} />
      <Route path="/edu-resources" element={<EducationalResources/>} />
      <Route path="/edu-today-magazine" element={<EducationTodayMagazine/>} />
      <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
      <Route path="/terms-of-service" element={<TermsOfService/>} />
      <Route path="/our-team" element={<OurTeam/>} />
      <Route path="/edu-events" element={<EduEvents/>} />
      <Route path="/subscribe" element={<Subscribe/>} />
      <Route path="/advertise" element={<Advertise/>} />
      <Route path="/pricing" element={<Pricing/>} />
      <Route path="/paymentprocess" element={<PaymentGateway />} />
      <Route path="/orderresult/:transactionid" element={<OrderResult />} />
      {/* <Route path="/state" element={<States onChildDataChange={function (data: string): void {
          throw new Error('Function not implemented.');
        } }/>} /> */}
    </Routes>
    </Router>
  );
};

export default App;

// -- JSON_UNQUOTE(JSON_EXTRACT(SymbolDetails,  CONCAT( '$[', i, '].symbol')))

