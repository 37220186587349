import Header from "../../components/Header/HeaderView";
import FooterView from "../Footer/FooterView";

const EducationTodayMagazine: React.FC = () => {

    return (
        <>
            <Header />

            <div className="container">
                <div className="row mt-5 mb-5">
                    <div className="col-md-12 school-features">
                        <h1>Education Today Magazine</h1>
                        <p >The Education Today Magazine, your ultimate source for insightful and engaging content covering a
                            diverse range of educational topics tailored for kids, youngsters, teens, and youth. At Education
                            Today, we are dedicated to providing informative articles, inspiring stories, practical tips, and
                            valuable resources to empower learners of all ages and stages of life.</p>

                        <p><h4>For Kids : <span className="sub-title"><i>( for age group 3 to 7 years</i>)</span></h4></p>
                        <p > Discover a world of wonder and learning with our engaging content designed specifically for kids.
                            From fun facts and educational games to interactive quizzes and captivating stories, Education Today
                            Magazine offers young readers an exciting journey of discovery and exploration across various
                            subjects, including science, history, literature, and more.</p>

                        

                        <p><h4>For Youngsters : <span className="sub-title"><i>( for age group 8 to 12 years</i>)</span></h4></p>
                        <p >Empower the next generation of leaders and innovators with our thought-provoking articles and
                            features aimed at youngsters. Whether it&#39;s career exploration, personal development, or academic
                            advice, Education Today Magazine provides valuable insights and inspiration to help young readers
                            navigate the challenges and opportunities of adolescence and beyond.</p>

                            <p><h4>For Teens : <span className="sub-title"><i>( for age group 13 to 19 years</i>)</span></h4></p>
                        <p >Navigate the teenage years with confidence and resilience with our targeted content for teens. From
                            study tips and exam strategies to mental health awareness and social issues, Education Today
                            Magazine addresses the unique needs and concerns of teenagers, empowering them to thrive
                            academically, socially, and emotionally during this transformative stage of life.</p>


                            <p><h4>For Youth : <span className="sub-title"><i>( 20+ students</i>)</span></h4></p>
                        
                        <p >Inspire action and drive change with our content tailored for youth. Education Today Magazine
                            explores topics such as leadership development, community engagement, career planning, and
                            global citizenship, providing young adults with the knowledge, skills, and motivation to make a
                            positive impact in their communities and beyond.</p>

                        <p>At Education Today Magazine, we believe that education is a lifelong journey, and learning should be
                            accessible, engaging, and meaningful for learners of all ages. Whether you&#39;re a curious kid,
                            ambitious youngster, curious teen, or aspiring youth, join us as we embark on a journey of discovery,
                            growth, and empowerment through the pages of Education Today Magazine. Subscribe now and
                            unlock the power of education today!</p>
                    </div>
                </div>
            </div>

            <FooterView />
        </>
    )

}

export default EducationTodayMagazine;