import Header from "../../components/Header/HeaderView";
import FooterView from "../Footer/FooterView";

const KeyFeatures: React.FC = () => {

    return (
        <>
            <Header />

            <div className="container">
                <div className="row mt-5 mb-5">
                    <div className="col-md-12 school-features">
                        <h1><b>Key Features of School Listing:</b></h1>
                        <p >1. Dedicated Web Page: Your school receives a personalized and professionally crafted web page,
                            serving as a digital
                            showcase to represent your institution uniquely.</p>
                        <p > 2. Comprehensive Overview: Showcase your school's strengths, academic programs, extracurricular
                            activities, faculty
                            profiles, facilities, and achievements to provide prospective students and parents with a
                            holistic understanding of what
                            your institution offers.</p>
                        <p > 3. Enhanced Visibility: Stand out in the crowded educational landscape by having a dedicated
                            space that captures the
                            attention of individuals actively seeking information about schools.</p>
                        <p > 4. Admission Lead Generation: Our platform actively supports the generation of admission leads,
                            connecting your school
                            with potential students and parents who express genuine interest in what your school provides.
                        </p>
                        <p > 5. Strategic Positioning: Leverage the School Listing service to strategically position your
                            institution, emphasizing its
                            unique value proposition and differentiating factors.</p>
                        <p > 6. Increased Credibility: A professionally curated web page enhances the credibility of your
                            school, providing a
                            trustworthy source of information for prospective stakeholders.</p>
                        <p >7. Interactive Multimedia: Incorporate multimedia elements such as images, videos, and virtual
                            tours to create a dynamic
                            and engaging presentation that goes beyond text.</p>
                        <p > 8. Easy Accessibility: Ensure that essential information about your school is easily accessible
                            to anyone exploring
                            educational options, making it convenient for prospective parents and students to learn more.
                        </p>
                        <p > 9. Regular Updates: Keep your information current with the ability to update and refresh
                            content, ensuring that your
                            school's listing remains relevant and reflective of any new developments.</p>
                        <p > 10. Customized Call-to-Action: Include specific calls-to-action to guide visitors toward key
                            actions, such as scheduling a
                            campus visit, requesting more information, or initiating the application process.</p>
                        <p > School Listing is not just about being present online; it's a strategic tool designed to
                            optimize your school's visibility,
                            attract the right audience, and drive successful admissions.</p>
                    </div>
                </div>
            </div>

            <FooterView />
        </>
    )

}

export default KeyFeatures;