import AsyncSelect from "react-select/async";
import { CitiesViewModel } from "./CitiesViewModel";


const CitiesView:React.FC<{ onCityChange: (city_id: number) => void,district_id:number}>=({onCityChange,district_id})=>
{
const viewModel=CitiesViewModel(onCityChange,district_id);
return (
    <>
      
    <AsyncSelect
    defaultOptions={viewModel.allOptions}
      isClearable
      isSearchable
      value={viewModel.selectedOption}
      onChange={viewModel.handleSelectChange}
      loadOptions={viewModel.loadOptions}
    />
  
    </>
  );
      }

      export default CitiesView;