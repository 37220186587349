export function SchoolRegistrationViewModel()
{
    var  registrationDetails:any={ school_id :0,

        udise_code:'',
        school_name:'',
        year_of_establishment:0,
        state_id:0,
        district_id:0,
        city_id:0,
        cluster_id:0,
        address:'',
        contact_no:'',
        email:'',
        location:'',
        instruction_medium:'',
        pre_primary_sectin_avilable:'',
        board_for_class_10th:'',
        board_for_class_12_plus:'', 
        classes:'',
        total_teachers:'',
        contract_teachers:'',
        school_type:'',
        pre_primary_teachers:'',
        male_teachers:'',
        female_teachers:'',
        meal:'',
        school_area:'',
        school_shifted_to_new_place:'',
        head_teacher:'',
        is_school_residential:'',
        residential_type:'',
        management:'',
        building:'',
        class_rooms:'',
        boys_toilet:'',
        girls_toilet:'',
        computer_aided_learning:'',
        electricity:'',
        wall:'',
        library:'',
        playground:'',
        books_in_library:'',
        drinking_water:'',
        ramps_for_disable:'',
        computers:'',
        canteen:'',
        mid_day_meal:'',
        transport_facility:'',
        password:''
      };
      //const [school_id,getSchoolId]=useState<{school_id :number} |0>(0);
     let school_id=0;

    return {school_id}
}