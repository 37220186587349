import AsyncSelect from "react-select/async";
import { DistrictsViewModel } from "./DistrictsViewModel";

const DistrictsView:React.FC<{ onDistrictChange: (district_id: number) => void,state_id:number}>=({onDistrictChange,state_id})=>
{
  debugger;
const ViewModel= DistrictsViewModel(onDistrictChange,state_id);

    return (
        <>
           
        <AsyncSelect
        defaultOptions={ViewModel.allOptions}
          isClearable
          isSearchable
          value={ViewModel.selectedOption}
          onChange={ViewModel.handleSelectChange}
          loadOptions={ViewModel.loadOptions}
        />
        
        </>
      );
    


}
export default DistrictsView;