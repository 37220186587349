import Header from "../../components/Header/HeaderView";
import AdmissionHelpMenu from "../../components/AdmissionHelpMenu/AdmissionHelpMenuView";
import Footer from "../../components/Footer/FooterView";
import { RegistrationSuccessViewModel } from "./RegistrationSuccessViewModel";

const RegistrationSuccessView:React.FC=()=>
{
 const viewMode= RegistrationSuccessViewModel();
    return(
        <><Header /><AdmissionHelpMenu/>
    <div className="container">
                <div className="card bx-shadow m-30 mh-400 mt-8">
                    <div className="jumbotron text-center">
                        <img src="./img/check.png" className="w-60" alt="" title=""/>
                        <h1 className="display-3">Thanks for Registration!</h1>
                        <p className="lead fs-15"><strong>Your details are now as part of Elite Membership with Education Today India</strong></p>
                        <hr/>
                        <p>Any queries? <a href="#">Reach us</a></p>
                        <p className="lead">
                            <button className="btn btn-danger btn-rounder w-120-br-50" onClick={viewMode.openSchoolLoginPage}>Login &gt; </button>
                        </p>
                    </div>
                </div>
            </div>
            <Footer/>
            </> 
    );


}
export  default RegistrationSuccessView;