import { useEffect, useState } from "react";
import { SchoolRepository } from "../../repository/SchoolRepository";

export function StateViewModel(onStateChange:any)
{

const repository =SchoolRepository();
    const [selectedOption, setSelectedOption] = useState<{ label: string; value: string } | null>(null);
    const [allOptions, setAllOptions] = useState<{ label: string; value: string }[]|[]>([]);
    const [optionsLoaded, setOptionsLoaded] = useState<boolean>(false);
    useEffect(() => {
    
      const fetchAllOptions = async () => {
        try {
            debugger ;
            
          const data:any = await repository.viewStates();
  debugger ;
        
          const options: { label: string; value: string }[] = data.map((item: any) => ({
            label: item.state_name,
            value: item.state_id.toString(),
          }));
  
          setAllOptions(options);
          setOptionsLoaded(true);
        } catch (error) {
          console.error('Error fetching all options:', error);
        }
      };
  
      fetchAllOptions();
    }, []); 
  
    const filterOptions = (inputValue: string) => {
      debugger;
  
      if(inputValue!='')
      {
        return  allOptions.filter((option) =>
      {
        return option.label.toLowerCase().includes(inputValue.toLowerCase());
        
      }
       
      );
    }else{
  
      return allOptions;
  
    }
    };
  
    const handleSelectChange = (selectedOption: { value: string; label: string } | null) => {
      debugger;
       
      setSelectedOption(selectedOption);
      onStateChange(selectedOption?(+selectedOption.value):0);
      console.log('Selected Option:', selectedOption);
      
    };
  
    const loadOptions = (inputValue: string, callback: (options: { label: string; value: string }[]) => void) => {
      debugger;
     
      const filteredOptions = filterOptions(inputValue);
      callback(filteredOptions);
    };
  
 
    
return {loadOptions,handleSelectChange,filterOptions,selectedOption,allOptions,optionsLoaded};
}