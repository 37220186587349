import Footer from "../../components/Footer/FooterView";
import Header from "../../components/Header/HeaderView";
import { ClustersListViewModel } from "./ClustersListViewModel";
import AdmissionHelpMenu from "../../components/AdmissionHelpMenu/AdmissionHelpMenuView";
const ClustersListView:React.FC=()=>
{
    

    const viewModel=ClustersListViewModel();

    return(
        <>
        <Header/>
        <AdmissionHelpMenu/>
    <div className="container mt-4 h-a" >
    <div className="text-center my-4"><h2 className="mb-1">List of the clusters in  </h2>  {viewModel.selectedCity.city_name}</div>
    <div className="row h-a" >
     <div className="col-md-12 mt-2"> 
    <div className="row"> 
    <div className="col-md-2"></div>  
    <div className="col-md-8 mt-2"> 
    <div className="card">
    <table className="table table-striped border">
    <tbody><tr><th> Sr. No. </th>
    <th>List of the clusters in {viewModel.selectedCity.city_name}</th>
    </tr>
    {
        viewModel.lstClusters.map((item:any,i)=>(
            <tr><td>{i+1} </td>
            <td><a onClick={()=>viewModel.clusterClick(item)}> {item.cluster_name}</a></td>
            </tr>
        ))
    }
   
    
    </tbody>
  </table>
   </div>
   </div>
   <div className="col-md-2"></div>  
  
    </div>  
    </div>  
    </div>
    </div>
    
    <Footer/>
    </>
    )
}
export default ClustersListView;