import CitiesView from "../Cities/CitiesView";
import ClustersView from "../Clusters/ClustersView";
import DistrictsView from "../Districts/DistrictsView";
import StatesView from "../States/StatesView";
import { RegistrationViewModel } from "./RegistrationViewModel";

const RegistrationView:React.FC<{school_id:number}>=({school_id})=>
{

const viewModel=RegistrationViewModel();
    return (
        <section className="bg-gray p-3">

{viewModel.schoolInformationShow ? (

			<div role="main" className="main">

				<div className="container">

					<div className="row">

						<div className="col-md-9 mx-auto">

							<div className="card p-3">
                                <h3 className="text-center">School Information/Details</h3>
                                <form>
                                    <div className="row">
                                <div className="col-md-6">
                                    <div className="mb-2">
                                      <label  className="form-label">UDISE Code <span className="text-danger">*</span></label>
                                      <input  type="text" className="form-control"   name="udise_code"  onChange={viewModel.handleChange}   value={viewModel.selectedSchool?viewModel.selectedSchool.udise_code:''}/>
                                     {(viewModel.selectedSchool.udise_code==''&& viewModel.isSchoolInformationButtonClick) ?(<label  className="m-0 text-danger">UDISE code required.</label>):''}
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="mb-2">
                                      <label  className="form-label">School Name <span className="text-danger">*</span></label>
                                      <input  type="text" className="form-control"   name="school_name"  onChange={viewModel.handleChange}   value={viewModel.selectedSchool?viewModel.selectedSchool.school_name:''}/>
                                      {(viewModel.selectedSchool.school_name==''&& viewModel.isSchoolInformationButtonClick) ?(<label  className="m-0 text-danger">School name required.</label>):''}
                                    </div>
                                    
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-2">
                                      <label  className="form-label">Instruction Medium <span className="text-danger">*</span></label>
                                      <input  type="text" className="form-control"   name="instruction_medium"  onChange={viewModel.handleChange}   value={viewModel.selectedSchool?viewModel.selectedSchool.instruction_medium:''}/>
                                      {(viewModel.selectedSchool.instruction_medium==''&& viewModel.isSchoolInformationButtonClick) ?(<label  className="m-0 text-danger">Instruction medium required.</label>):''}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-2">
                                      <label  className="form-label">School Type <span className="text-danger">*</span></label>
                                      <select className="form-select" name="school_type"  onChange={viewModel.handleChange}   value={viewModel.selectedSchool?viewModel.selectedSchool.school_type:''} aria-label="Default select example">
                                        <option>Select</option>
                                        <option value="Co-educational">Co-educational</option>
                                        <option value="Girls Only">Girls Only</option>
                                        <option value="Boys Only">Boys Only</option>
                                      </select>
                                      {((viewModel.selectedSchool.school_type=='Select' || viewModel.selectedSchool.school_type=='') && viewModel.isSchoolInformationButtonClick) ?(<label  className="m-0 text-danger">School type required.</label>):''}

                                    </div>
                                    
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-2">
                                      <label  className="form-label">Classes <span className="text-danger">*</span></label>
                                      <select className="form-select" aria-label="Default select example" name="classes"  onChange={viewModel.handleChange}   value={viewModel.selectedSchool?viewModel.selectedSchool.classes:''}>
                                        <option  >Select</option>
                                        {viewModel.lstClasses.map((item:any, i:any) =>
                                                
                                             {
return (<option   value={item.class_name}>{item.class_name}</option>);
                                             }   
                                        )
                                        }
                                                
                                        
                                        {/* <option value="2">Pre-Primary to Primary</option>
                                        <option value="3">Pre-Primary To 4</option>
                                        <option value="4">Primary To 4</option>
                                       
                                        <option value="5">Pre-Primary To 7</option>
                                        <option value="6">Primary To 7</option>
                                      
                                        <option value="8">Pre-Primary To 10</option>
                                        <option value="9">Primary To 10</option>
                                        
                                        <option value="11">Pre-Primary To 12</option>
                                        <option value="12">Primary To 12</option>
                                        <option value="4">1 To 4</option>
                                        <option value="7">1 To 7</option>
                                        <option value="10">1 To 10</option>
                                        <option value="13">1 To 12</option>
                                        <option value="14">8 To 10</option>
                                        <option value="15">8 To 12</option> */}
                                      </select>
                                      {((viewModel.selectedSchool.classes=='Select' || viewModel.selectedSchool.classes=='') && viewModel.isSchoolInformationButtonClick) ?(<label  className="m-0 text-danger">Classes required.</label>):''}

                                    </div>
                                    
                                    
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-2">
                                      <label  className="form-label">Year of Establishment</label>
                                      <input  type="text" className="form-control"  
                                       name="year_of_establishment"  onChange={viewModel.handleChange}   value={viewModel.selectedSchool?viewModel.selectedSchool.year_of_establishment:''}/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-2">
                                      <label  className="form-label">School Area <span className="text-danger">*</span></label>
                                      <select className="form-select" aria-label="Default select example"  name ="school_area"  onChange={viewModel.handleChange}   value={viewModel.selectedSchool?viewModel.selectedSchool.school_area:''}>
                                        <option  >Select</option>
                                        <option value="1">Urban</option>
                                        <option value="2">Semi-Urban</option>
                                        <option value="3">Rural</option>
                                      </select>
                                      {((viewModel.selectedSchool.school_area=='Select' || viewModel.selectedSchool.school_area=='' )&& viewModel.isSchoolInformationButtonClick) ?(<label  className="m-0 text-danger">School area required.</label>):''}


                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-2">
                                      <label  className="form-label">School Shifted to New Place </label> <br/>
                                      <div className="form-check form-check-inline">
                                        <input className="form-check-input mt-6" type="radio" name="school_shifted_to_new_place" id="inlineRadio5" value="Yes"   onChange={viewModel.handleChange}  checked={(viewModel.selectedSchool?viewModel.selectedSchool.school_shifted_to_new_place:'') === "Yes"}/>
                                        <label className="form-check-label" >Yes</label>
                                      </div>
                                      <div className="form-check form-check-inline">
                                        <input className="form-check-input mt-6" type="radio" name="school_shifted_to_new_place" id="inlineRadio5" value="No" onChange={viewModel.handleChange}  checked={(viewModel.selectedSchool?viewModel.selectedSchool.school_shifted_to_new_place:'') === "No"}/>
                                        <label className="form-check-label" >No</label>


                                      </div>
                                        
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-2">
                                      <label  className="form-label">Management</label>
                                      <select className="form-select" aria-label="Default select example" name="management"   onChange={viewModel.handleChange}   value={viewModel.selectedSchool?viewModel.selectedSchool.management:''}>
                                        <option  >Select</option>
                                        <option value="1">Pvt</option>
                                        <option value="2">Unaided</option>
                                        <option value="3">Aided</option>
                                      </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-2">
                                      <label  className="form-label">Building</label>
                                      <select className="form-select" aria-label="Default select example" name="building"  onChange={viewModel.handleChange}   value={viewModel.selectedSchool?viewModel.selectedSchool.building:''}>
                                        <option  >Select</option>
                                        <option value="1">Owned</option>
                                        <option value="2">Government</option>
                                        <option value="3">Rented</option>
                                      </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-2">
                                      <label  className="form-label">Pre Primary Section Available</label> <br/>
                                      <div className="form-check form-check-inline">
                                        <input className="form-check-input mt-6" type="radio" name="pre_primary_sectin_avilable" id="inlineRadio1" value="Yes"  onChange={viewModel.handleChange} checked={(viewModel.selectedSchool?viewModel.selectedSchool.pre_primary_sectin_avilable:'') === "Yes"}/>
                                        <label className="form-check-label" >Yes</label>
                                      </div>
                                      <div className="form-check form-check-inline">
                                        <input className="form-check-input mt-6" type="radio" name="pre_primary_sectin_avilable" id="inlineRadio1" value="No" onChange={viewModel.handleChange}   checked={(viewModel.selectedSchool?viewModel.selectedSchool.pre_primary_sectin_avilable:'') === "No"} />
                                        <label className="form-check-label" >No</label>
                                      </div>
                                        
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-2">
                                      <label  className="form-label">Board for Class 10th <span className="text-danger">*</span></label>
                                      <select className="form-select" aria-label="Default select example" name="board_for_class_10th"  onChange={viewModel.handleChange}   value={viewModel.selectedSchool?viewModel.selectedSchool.board_for_class_10th:''}>
                                        <option  >Select</option>
                                        <option value="1">Others</option>
                                        <option value="2">State</option>
                                        <option value="3">CBSE</option>
                                        <option value="3">ICSE</option>
                                      </select>
                                      {((viewModel.selectedSchool.board_for_class_10th=='Select' || viewModel.selectedSchool.board_for_class_10th=='') && viewModel.isSchoolInformationButtonClick) ?(<label  className="m-0 text-danger">Board for class 10th required.</label>):''}

                                    </div>
                                </div>
                                
                                </div>  
                                <div className="row">
                                    <div className="col-md-6 mb-2">

										<b>1 of 5</b>
                                        <div className="progress">

											<div className="progress-bar" 
      role="progressbar" 
      style={{ width: `${viewModel.progress}%` }} 
      aria-valuenow={viewModel.progress} 
      aria-valuemin={0} 
      aria-valuemax={100} ></div>

										</div>
										

									</div>
                                    <div className="col-md-6 text-end my-auto">

										<button className="btn btn-danger btn-rounder w-120 b-r-50"  onClick={viewModel.schoolInformationNextClick}>Next &gt; </button>

									</div>
                                </div>
                                  </form>
								

							</div>


						</div>





					</div>

				</div>

			</div>
):''}
      {viewModel.schoolContactShow ? (
            <div role="main" className="main mt-3">

				<div className="container">

					<div className="row">

						<div className="col-md-9 mx-auto">

							<div className="card p-3">
                                <h3 className="text-center">Address /Contact Information</h3>
                                <form>
                                    <div className="row">
                                <div className="col-md-6">
                                    <div className="mb-2">
                                      <label  className="form-label">State  <span className="text-danger">*</span></label>
                                      <StatesView  onStateChange={viewModel.handleStateChange}/>
                                      {((viewModel.selectedSchool.state_id==0 ) && viewModel.isContactInformationButtonClick) ?(<label  className="m-0 text-danger">State required.</label>):''}

                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-2">
                                      <label  className="form-label">Districts <span className="text-danger">*</span></label>
                                      <DistrictsView onDistrictChange={viewModel.handleDistrictChange} state_id={viewModel.state_id}/>
                                      {((viewModel.selectedSchool.district_id==0 ) && viewModel.isContactInformationButtonClick) ?(<label  className="m-0 text-danger">Districts required.</label>):''}

                                    </div>
                                    
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-2">
                                      <label  className="form-label">City(Blocks) <span className="text-danger">*</span></label>
                                      
                                      <CitiesView onCityChange={viewModel.handleCityChange} district_id={viewModel.district_id}/>
                                      {((viewModel.selectedSchool.city_id==0 ) && viewModel.isContactInformationButtonClick) ?(<label  className="m-0 text-danger">City required.</label>):''}

                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-2">
                                      <label  className="form-label">Cluster <span className="text-danger">*</span></label>
                                    <ClustersView onClusterChange={viewModel.handleClusterChange} city_id={viewModel.city_id}/>
                                    {((viewModel.selectedSchool.cluster_id==0 ) && viewModel.isContactInformationButtonClick) ?(<label  className="m-0 text-danger">Cluster required.</label>):''}

                                    </div>
                                    
                                </div>


<div className="col-md-6">
  <div className="row">
  <div className="col-md-12">
  <label  className="form-label">Location (lat, long)</label>

  </div>

  <div className="col-md-5">                                <input
        type="text"
        name="latitude"
        className="form-control"
        placeholder="Latitude"
        value={viewModel.coordinates.latitude}
        onChange={viewModel.handleCoordinatesChange}
        onBlur={viewModel.handleBlur}
      /></div>
  <div className="col-md-2 text-center"><span >,</span></div>
  <div className="col-md-5">

  <input
        type="text"
        name="longitude"
        className="form-control"
        placeholder="Longitude"
        value={viewModel.coordinates.longitude}
        onChange={viewModel.handleCoordinatesChange}
        onBlur={viewModel.handleBlur}
      />

  </div>

  </div>

</div>


                                 
                               
                                <div className="col-md-6">
                                    <div className="mb-2">
                                      <label  className="form-label">Contact No<span className="text-danger">*</span></label>
                                      <input type="number" className="form-control"  name="contact_no"  onChange={viewModel.handleChange}   value={viewModel.selectedSchool?viewModel.selectedSchool.contact_no:''} />
                                      {((viewModel.selectedSchool.contact_no=='0' || viewModel.selectedSchool.contact_no=='' ) && viewModel.isContactInformationButtonClick) ?(<label  className="m-0 text-danger">Contact required.</label>):''}

                                    </div>
                                </div>
                               
                               

                                <div className="col-md-12">
                                    <div className="mb-2">
                                      <label  className="form-label">Address<span className="text-danger">*</span></label>
                                      <textarea rows={3} className="form-control"  name="address"  onChange={viewModel.handleChange}   value={viewModel.selectedSchool?viewModel.selectedSchool.address:''} />
                                      {((viewModel.selectedSchool.address==''   ) && viewModel.isContactInformationButtonClick) ?(<label  className="m-0 text-danger">Addres required.</label>):''}

                                    </div>
                                </div>


                                </div> 
                                <div className="row">
                                    <div className="col-md-6 mb-2">

										<b>2 of 5</b>

										<div className="progress">

											<div className="progress-bar" 
      role="progressbar" 
      style={{ width: `${viewModel.progress}%` }} 
      aria-valuenow={viewModel.progress} 
      aria-valuemin={0} 
      aria-valuemax={100}   ></div>

										</div>

									</div>
                                    <div className="col-md-6 text-end my-auto">

										<button className="btn btn-danger btn-rounder w-120 b-r-50" onClick={viewModel.contactInformationNextClick} >Next &gt; </button>

									</div>
                                </div>
                                  </form>
								

							</div>

						</div>





					</div>

				</div>

			</div>

    ):''}

{viewModel.resourcesShow?(
            <div role="main" className="main mt-3">

				<div className="container">

					<div className="row">

						<div className="col-md-9 mx-auto">

							<div className="card p-3">
                                <h3 className="text-center">Resource Details</h3>
                                <h5>Faculty Information</h5>
                                <form>
                                    <div className="row">
                                {/* <div className="col-md-6">
                                    <div className="mb-2">
                                      <label  className="form-label">Head Teachers  </label>
                                      <input  type="text" className="form-control"  />
                                    </div>
                                </div> */}
                                <div className="col-md-6">
                                    <div className="mb-2">
                                      <label  className="form-label">Head Teacher </label>
                                      <input  type="text" className="form-control"  name="head_teacher"  onChange={viewModel.handleChange}   value={viewModel.selectedSchool?viewModel.selectedSchool.head_teacher:''}  />
                                    </div>
                                    
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-2">
                                      <label  className="form-label">Total Teachers </label>
                                      <input  type="text" className="form-control" 
                                      name="total_teachers"  onChange={viewModel.handleChange}   value={viewModel.selectedSchool?viewModel.selectedSchool.total_teachers:0}
                                      />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-2">
                                      <label  className="form-label">Pre Primary Teachers </label>
                                      <input  type="text" className="form-control"  
                                       name="pre_primary_teachers"  onChange={viewModel.handleChange}   value={viewModel.selectedSchool?viewModel.selectedSchool.pre_primary_teachers:0}
                                      
                                      />
                                    </div>
                                    
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-2">
                                      <label  className="form-label">Primary Teachers </label>
                                      <input  type="text" className="form-control" 
                                       name="primary_teachers"  onChange={viewModel.handleChange}   value={viewModel.selectedSchool?viewModel.selectedSchool.primary_teachers:0}
                                      
                                      />
                                    </div>
                                    
                                    
                                </div>
                               
                                <div className="col-md-6">
                                    <div className="mb-2">
                                      <label  className="form-label">Middle School Teachers</label>
                                      <input type="number" className="form-control" 
                                       name="middle_school_teachers"  onChange={viewModel.handleChange}   value={viewModel.selectedSchool?viewModel.selectedSchool.middle_school_teachers:0}
                                      
                                      />
                                      
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-2">
                                        <label  className="form-label">High SchoolTeachers </label>
                                        <input  type="text" className="form-control"  
                                        
                                       name="high_school_teachers"  onChange={viewModel.handleChange}   value={viewModel.selectedSchool?viewModel.selectedSchool.high_school_teachers:0}
                                        
                                        />
                                      </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-2">
                                        <label  className="form-label">Number of Female Teacher </label>
                                        <input  type="text" className="form-control"   
                                        
                                       name="female_teachers"  onChange={viewModel.handleChange}   value={viewModel.selectedSchool?viewModel.selectedSchool.female_teachers:0}
                                        
                                        />
                                      </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-2">
                                        <label  className="form-label">Number of Male Teacher </label>
                                        <input  type="text" className="form-control" 
                                        
                                       name="male_teachers"  onChange={viewModel.handleChange}   value={viewModel.selectedSchool?viewModel.selectedSchool.male_teachers:0}
                                        
                                        />
                                      </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-2">
                                        <label  className="form-label">Number of Contract Teachers </label>
                                        <input  type="text" className="form-control" 
                                       name="contract_teachers"  onChange={viewModel.handleChange}   value={viewModel.selectedSchool?viewModel.selectedSchool.contract_teachers:0}
                                        
                                        />
                                      </div>
                                </div>
                               
                                </div>  
                                <div className="row">
                                    <div className="col-md-6 mb-2">

										<b>3 of 5</b>

										<div className="progress">

											<div className="progress-bar" 
      role="progressbar" 
      style={{ width: `${viewModel.progress}%` }} 
      aria-valuenow={viewModel.progress} 
      aria-valuemin={0} 
      aria-valuemax={100}  ></div>

										</div>

									</div>
                                    <div className="col-md-6 text-end my-auto">

										<button className="btn btn-danger btn-rounder w-120 b-r-50" onClick={viewModel.resourceInformationNextClick} >Next &gt; </button>

									</div>
                                </div>
                                  </form>
								

							</div>

						</div>





					</div>

				</div>

			</div>
):''}

{viewModel.facilityShow?(
            <div role="main" className="main mt-3">

				<div className="container">

					<div className="row">

						<div className="col-md-9 mx-auto">

							<div className="card p-3">
                                <h3 className="text-center">Facility Details </h3>
                                <form>
                                    <div className="row">
                                <div className="col-md-6">
                                    <div className="mb-2">
                                      <label  className="form-label">Total number of Class Rooms </label>
                                      <input  type="text" className="form-control"  
                                       name="class_rooms"  onChange={viewModel.handleChange}   value={viewModel.selectedSchool?viewModel.selectedSchool.class_rooms:0}
                                      
                                      />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-2">
                                      <label  className="form-label">Number of Boys Toilet </label>
                                      <input  type="text" className="form-control" 
                                      
                                      name="boys_toilet"  onChange={viewModel.handleChange}   value={viewModel.selectedSchool?viewModel.selectedSchool.boys_toilet:0}
                                      
                                      />
                                    </div>
                                    
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-2">
                                      <label  className="form-label">Number of Girls Toilet </label>
                                      <input  type="text" className="form-control" 
                                      
                                      name="girls_toilet"  onChange={viewModel.handleChange}   value={viewModel.selectedSchool?viewModel.selectedSchool.girls_toilet:0}
                                      
                                      />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-2">
                                      <label  className="form-label">Mid-day Meal Status / Info </label>
                                      <select className="form-select" aria-label="Default select example" name="meal"  onChange={viewModel.handleChange}   value={viewModel.selectedSchool?viewModel.selectedSchool.meal:''}>
                                        <option  >Select</option>
                                        <option value="1">Not Provided</option>
                                        <option value="2">Provided</option>
                                      </select>
                                    </div>
                                    
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-2">
                                      <label  className="form-label">Is School Residential </label>

<br/>
                                      <div className="form-check form-check-inline">
                                        <input className="form-check-input mt-6" type="radio" name="is_school_residential" id="inlineRadio5" value="Yes"   onChange={viewModel.handleChange}  checked={(viewModel.selectedSchool?viewModel.selectedSchool.is_school_residential:'') === "Yes"}/>
                                        <label className="form-check-label" >Yes</label>
                                      </div>
                                      <div className="form-check form-check-inline">
                                        <input className="form-check-input mt-6" type="radio" name="is_school_residential" id="inlineRadio5" value="No" onChange={viewModel.handleChange}  checked={(viewModel.selectedSchool?viewModel.selectedSchool.is_school_residential:'') === "No"}/>
                                        <label className="form-check-label" >No</label>


                                      </div>


                                      {/* <input  type="text" className="form-control" 
                                      name="is_school_residential"  onChange={viewModel.handleChange}   value={viewModel.selectedSchool?viewModel.selectedSchool.is_school_residential:0}
                                      
                                      
                                      /> */}
                                    </div>
                                    
                                    
                                </div>
                               
                                <div className="col-md-6">
                                    <div className="mb-2">
                                      <label  className="form-label">Residential Type </label>
                                      <select className="form-select" aria-label="Default select example" name="residential_type"  onChange={viewModel.handleChange}   value={viewModel.selectedSchool?viewModel.selectedSchool.residential_type:''}>
                                        <option  >Select</option>
                                        <option value="1">Not Applicable</option>
                                        <option value="2">Applicable</option>
                                      </select>
                                      
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-2">
                                        <label  className="form-label">Electricity </label>
                                        <br/>
                                      <div className="form-check form-check-inline">
                                        <input className="form-check-input mt-6" type="radio" name="electricity" id="inlineRadio5" value="Yes"   onChange={viewModel.handleChange}  checked={(viewModel.selectedSchool?viewModel.selectedSchool.electricity:'') === "Yes"}/>
                                        <label className="form-check-label" >Yes</label>
                                      </div>
                                      <div className="form-check form-check-inline">
                                        <input className="form-check-input mt-6" type="radio" name="electricity" id="inlineRadio5" value="No" onChange={viewModel.handleChange}  checked={(viewModel.selectedSchool?viewModel.selectedSchool.electricity:'') === "No"}/>
                                        <label className="form-check-label" >No</label>


                                      </div>



                                       
                                      </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-2">
                                        <label  className="form-label">Wall </label>
                                        <select className="form-select" aria-label="Default select example" name="wall"  onChange={viewModel.handleChange}   value={viewModel.selectedSchool?viewModel.selectedSchool.wall:''}>
                                            <option  >Select</option>
                                            <option value="1">Pucca</option>
                                            <option value="2">Thatched</option>
                                          </select>
                                      </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-2">
                                        <label  className="form-label">Library </label>

                                        <br/>
                                      <div className="form-check form-check-inline">
                                        <input className="form-check-input mt-6" type="radio" name="library" id="inlineRadio5" value="Yes"   onChange={viewModel.handleChange}  checked={(viewModel.selectedSchool?viewModel.selectedSchool.library:'') === "Yes"}/>
                                        <label className="form-check-label" >Yes</label>
                                      </div>
                                      <div className="form-check form-check-inline">
                                        <input className="form-check-input mt-6" type="radio" name="library" id="inlineRadio5" value="No" onChange={viewModel.handleChange}  checked={(viewModel.selectedSchool?viewModel.selectedSchool.library:'') === "No"}/>
                                        <label className="form-check-label" >No</label>


                                      </div>

 
                                      </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-2">
                                        <label  className="form-label">Playground </label>

                                        <br/>
                                      <div className="form-check form-check-inline">
                                        <input className="form-check-input mt-6" type="radio" name="playground" id="inlineRadio5" value="Yes"   onChange={viewModel.handleChange}  checked={(viewModel.selectedSchool?viewModel.selectedSchool.playground:'') === "Yes"}/>
                                        <label className="form-check-label" >Yes</label>
                                      </div>
                                      <div className="form-check form-check-inline">
                                        <input className="form-check-input mt-6" type="radio" name="playground" id="inlineRadio5" value="No" onChange={viewModel.handleChange}  checked={(viewModel.selectedSchool?viewModel.selectedSchool.playground:'') === "No"}/>
                                        <label className="form-check-label" >No</label>


                                      </div>


                                      
                                      </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-2">
                                        <label  className="form-label">Total Books in Library </label>
                                        <input  type="text" className="form-control"  
                                        
                                        name="books_in_library"  onChange={viewModel.handleChange}   value={viewModel.selectedSchool?viewModel.selectedSchool.books_in_library:0}
                                        
                                        />
                                      </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-2">
                                        <label  className="form-label">Drinking Water </label>
                                        <select className="form-select" aria-label="Default select example" name ="drinking_water"  onChange={viewModel.handleChange}   value={viewModel.selectedSchool?viewModel.selectedSchool.drinking_water:''}>
                                            <option  >Select</option>
                                            <option value="1">Tap Water</option>
                                            <option value="2">Bore Water</option>
                                            <option value="3">Filter Water</option>
                                          </select>
                                      </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-2">
                                        <label  className="form-label">Ramps for Disabled </label>

                                        <br/>
                                      <div className="form-check form-check-inline">
                                        <input className="form-check-input mt-6" type="radio" name="ramps_for_disable" id="inlineRadio5" value="Yes"   onChange={viewModel.handleChange}  checked={(viewModel.selectedSchool?viewModel.selectedSchool.ramps_for_disable:'') === "Yes"}/>
                                        <label className="form-check-label" >Yes</label>
                                      </div>
                                      <div className="form-check form-check-inline">
                                        <input className="form-check-input mt-6" type="radio" name="ramps_for_disable" id="inlineRadio5" value="No" onChange={viewModel.handleChange}  checked={(viewModel.selectedSchool?viewModel.selectedSchool.ramps_for_disable:'') === "No"}/>
                                        <label className="form-check-label" >No</label>


                                      </div>

                                        {/* <input  type="text" className="form-control"  
                                        
                                        name="ramps_for_disable"  onChange={viewModel.handleChange}   value={viewModel.selectedSchool?viewModel.selectedSchool.ramps_for_disable:''}
                                        
                                        /> */}
                                      </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-2">
                                      <label  className="form-label">Canteen</label> <br/>
                                      <div className="form-check form-check-inline">
                                        <input className="form-check-input mt-6" type="radio"     value="Yes"
                                        name="canteen" id="inlineRadio5"   onChange={viewModel.handleChange}  checked={(viewModel.selectedSchool?viewModel.selectedSchool.canteen:'') === "Yes"}
                                        />
                                        <label className="form-check-label" >Yes</label>
                                      </div>
                                      <div className="form-check form-check-inline">
                                        <input className="form-check-input mt-6" type="radio"   value="No"
                                        name="canteen" id="inlineRadio5"   onChange={viewModel.handleChange}  checked={(viewModel.selectedSchool?viewModel.selectedSchool.canteen:'') === "No"}
                                        
                                        />
                                        <label className="form-check-label" >No</label>
                                      </div>
                                        
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-2">
                                        <label  className="form-label">Transport Facility </label>
                                        <select className="form-select" aria-label="Default select example" name="transport_facility"  onChange={viewModel.handleChange}   value={viewModel.selectedSchool?viewModel.selectedSchool.transport_facility:''}>
                                            <option  >Select</option>
                                            <option value="1">Available</option>
                                            <option value="2">Not Available</option>
                                          </select>
                                      </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-2">
                                        <label  className="form-label">Computer Aided Learning Status </label>
                                        <select className="form-select" aria-label="Default select example" 
                                        name="computer_aided_learning"  onChange={viewModel.handleChange}   value={viewModel.selectedSchool?viewModel.selectedSchool.computer_aided_learning:''}
                                        
                                        >
                                            <option  >Select</option>
                                            <option value="1">Available</option>
                                            <option value="2">Not Available</option>
                                          </select>
                                      </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-2">
                                        <label  className="form-label">If Available Number of Computers available </label>
                                        <input  type="text" className="form-control" 
                                        name="computers"  onChange={viewModel.handleChange}   value={viewModel.selectedSchool?viewModel.selectedSchool.computers:0}
                                        
                                        
                                        />
                                      </div>
                                </div>
                               
                                </div> 
                                <div className="row">
                                    <div className="col-md-6 mb-2">

										<b>4 of 5</b>
                                        <div className="progress">

											<div className="progress-bar" 
      role="progressbar" 
      style={{ width: `${viewModel.progress}%` }} 
      aria-valuenow={viewModel.progress} 
      aria-valuemin={0} 
      aria-valuemax={100} ></div>

										</div>
										

									</div>
                                    <div className="col-md-6 text-end my-auto">

										<button className="btn btn-danger btn-rounder w-120 b-r-50"   onClick={viewModel.facilityInformationNextClick}  >Next &gt; </button>

									</div>
                                </div>
                                  </form>
								

							</div>

						</div>





					</div>

				</div>

			</div>
):''}



{viewModel.loginInformationShow ? (
            <div role="main" className="main mt-3">


				<div className="container">

					<div className="row">

						<div className="col-md-9 mx-auto">

							<div className="card p-3">
                                <h3 className="text-center">Login Information</h3>
                                <form>
                                    <div className="row">
                                    <div className="col-md-6">

                                    <div className="row">
                                    <div className="col-md-12">
                                    <div className="mb-2">
                                        <label  className="form-label">Email <span className="text-danger">*</span></label>
                                        <input  type="email" name="email" className="form-control"  onChange={viewModel.handleChange}   value={viewModel.selectedSchool?viewModel.selectedSchool.email:''} />
                                         {((viewModel.selectedSchool.email=='' || !(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/).test(viewModel.selectedSchool.email) ) && viewModel.isSaveButtonClick) ?(<label  className="m-0 text-danger">Invalid email.</label>):''}

                                      </div>
                                </div>
                               

                                <div className="col-md-12">
                                    <div className="mb-2">
                                      <label  className="form-label">Password<span className="text-danger">*</span></label>
                                      <input type="password" className="form-control" name="password" onChange={viewModel.handleChange}   value={viewModel.selectedSchool?viewModel.selectedSchool.password:''} />
                                      {((viewModel.selectedSchool.password=='' ) && viewModel.isSaveButtonClick) ?(<label  className="m-0 text-danger">Password required.</label>):''}

                                    </div>
                                </div>


                                <div className="col-md-12">
                                    <div className="mb-2">
                                      <label  className="form-label">Re-Password<span className="text-danger">*</span></label>
                                      <input type="password" className="form-control"  name="re_password" onChange={viewModel.handleChange}   value={viewModel.selectedSchool?viewModel.selectedSchool.re_password:''} />
                                      {((viewModel.selectedSchool.re_password=='' ) && viewModel.isSaveButtonClick) ?(<label  className="m-0 text-danger">Re-Password required.</label>):''}
                                      
                                    </div>
                                </div>
                                {((viewModel.selectedSchool.password!='' &&  viewModel.selectedSchool.re_password!='' && viewModel.selectedSchool.password!=viewModel.selectedSchool.re_password ) && viewModel.isSaveButtonClick) ?(<label  className="m-0 text-danger">Password required.</label>):''}


                                    </div>

                                    </div>

                                    <div className="col-md-6">
                                      <img src={viewModel.imageSrc}  className="sc-image" onError={viewModel.handleImageError} alt="Selected File" />
                                    <input type="file" id="file_type" ref={viewModel.fileInputRef}  className="form-control" placeholder="file" onChange={viewModel.handleImageChange} />
                                  
                                    </div>

                            
                                </div> 
                                <div className="row">
                                    <div className="col-md-6 mb-2">

										<b>5 of 5</b>

										<div className="progress">

											<div className="progress-bar" 
      role="progressbar" 
      style={{ width: `${viewModel.progress}%` }} 
      aria-valuenow={viewModel.progress} 
      aria-valuemin={0} 
      aria-valuemax={100}    ></div>

										</div>

									</div>
                                    <div className="col-md-6 text-end my-auto">

										<button className="btn btn-danger btn-rounder w-120 b-r-50" onClick={viewModel.saveClick} >Register &gt; </button>

									</div>
                                </div>
                                  </form>
								

							</div>

						</div>





					</div>

				</div>

			</div>

    ):''}


		</section>
    );

}


export default RegistrationView;