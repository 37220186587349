import { useEffect, useState } from "react";
import { SchoolRepository } from "../../repository/SchoolRepository";

export function ClustersViewModel(onClusterChange:any,city_id:any)
{
    const schoolRepository=SchoolRepository();
    const [selectedOption, setSelectedOption] = useState<{ label: string; value: string } | null>(null);
    const [allOptions, setAllOptions] = useState<{ label: string; value: string }[]|[]>([]);
   
    useEffect(() => {
  
      if( typeof(city_id)=='undefined' || city_id==0 )
      {
       
        setAllOptions([]);
      }
  
     
      const fetchAllOptions = async () => {
        try {
          debugger;
  
         if( typeof(city_id)!='undefined' )
         {
     
           
          const data:any =await schoolRepository.viewClusters(city_id);
  debugger ;
        
          const options: { label: string; value: string }[] = data.map((item: any) => ({
            label: item.cluster_name,
            value: item.cluster_id.toString(),
          }));
  
          setAllOptions(options);
          
        }
        } catch (error) {
          console.error('Error fetching all options:', error);
        }
      };
      fetchAllOptions();
    }, [city_id]); 
  
    const filterOptions = (inputValue: string) => {
      debugger;
  
      if(inputValue!='')
      {
        return  allOptions.filter((option) =>
      {
        return option.label.toLowerCase().includes(inputValue.toLowerCase());
        
      }
       
      );
    }else{
  
      return allOptions;
  
    }
    };
  
    const handleSelectChange = (selectedOption: { value: string; label: string } | null) => {
      debugger;
       
      setSelectedOption(selectedOption);
      onClusterChange(selectedOption?(+selectedOption.value):0);
      console.log('Selected Option:', selectedOption);
      
    };
  
    const loadOptions = (inputValue: string, callback: (options: { label: string; value: string }[]) => void) => {
      debugger;
     
      const filteredOptions = filterOptions(inputValue);
      callback(filteredOptions);
    };

    return {selectedOption,handleSelectChange,loadOptions,allOptions}
}