
import React, { useState } from "react";
import Header from "../../components/Header/HeaderView";
import Registration from "../../components/Registration/RegistrationView";
import AdmissionHelpMenu from "../../components/AdmissionHelpMenu/AdmissionHelpMenuView";
import Footer from "../../components/Footer/FooterView";
import { SchoolRegistrationViewModel } from "./SchoolRegistrationViewModel";
const SchoolRegistrationView:React.FC=()=>
{

const viewModel=SchoolRegistrationViewModel();

    return(
        
        <><Header />
        {/* <AdmissionHelpMenu/> */}
        <Registration school_id={viewModel.school_id}/>
        <Footer/>
        
        </>

    );


}
export default SchoolRegistrationView;