import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { SchoolRepository } from "../../repository/SchoolRepository";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { useDispatch } from "react-redux";
import { ActionType } from "../../store/Page.Actions";
export function RegistrationViewModel()
{
  const dispatch = useDispatch();
  const repository=SchoolRepository();
    const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
 
        const target = event.target as HTMLImageElement;
        target.src = 'https://www.komysafety.com/images/banner/no-image.png';
      };
      const navigate = useNavigate();
      const [state_id,selectedStateId]=useState<number |0>(0);
      const [district_id,selectedDistrictId]=useState<number |0>(0);
      const [city_id,selectedCityId]=useState<number |0>(0);
      const [cluster_id,selectedClusterId]=useState<number |0>(0);

      const [location,setLocation]=useState('');

      const [lstClasses,setClasses]=useState([]);

      const [file, setFile] = useState<File | undefined>(undefined);
      const fileInputRef = useRef<HTMLInputElement | null>(null);
        const [imageSrc, setImageSrc] = useState<string | undefined>(undefined);
    
        const [coordinates, setCoordinates] = useState<{ latitude: string; longitude: string }>({
          latitude: '',
          longitude: '',
        });
      
        const handleCoordinatesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          const { name, value } = event.target;
      
          setCoordinates((prevCoordinates) => ({
            ...prevCoordinates,
            [name]: value,
          }));
        };
        useEffect(() => {
          getClassesForRegistration();
          
        },[]);



    const    getClassesForRegistration=async ()=>
        {
debugger;
          const data:any = await repository.getClassesForRegistration();
          setClasses(data);

        }
const onCoordinatesChange=(location:any)=>
{
  setLocation((location.latitude+','+location.longitude))
debugger;

}

        const handleBlur = () => {
          const latitude = parseFloat(coordinates.latitude);
          const longitude = parseFloat(coordinates.longitude);
      
          if (!isNaN(latitude) && !isNaN(longitude)) {
            onCoordinatesChange({ latitude, longitude });
          }
        };
      


        const handleImageChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
            debugger;
            if (e.target instanceof HTMLInputElement && e.target.files && e.target.files.length > 0) {
                debugger;
                const selectedFile = e.target.files[0];
    
                if(selectedFile.size > ((1024 * 1024)*3)){
                   Swal.fire("Opps", "Max Image size is 3MB ", "error");
                }
                else{
                    setFile(selectedFile);
                    
                    const reader = new FileReader();
                    reader.onloadend = () => {
                    setImageSrc(reader.result as string);
                    };
                    reader.readAsDataURL(selectedFile);
                    debugger;
                }
            }
        };
    
     
    
    
    
      const handleStateChange = (state_id: number) => {
        debugger;
       
       selectedStateId(state_id);
       selectedSchool.state_id=state_id;
      };
    
      const handleDistrictChange = (district_id: number) => {
        debugger;
        
       selectedDistrictId(district_id);
       selectedSchool.district_id=district_id;
    
      };
      
      const handleCityChange = (city_id: number) => {
        debugger;
       
       selectedCityId(city_id);
       selectedSchool.city_id=city_id;
      };
      const handleClusterChange = (cluster_id: number) => {
        debugger;
       
        selectedClusterId(cluster_id);
       selectedSchool.cluster_id=cluster_id;
    
      };
      
      const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        debugger;
        if ('value' in e.target) {
            const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;
            setSchoolDetails((prev) => ({ ...prev, [name]: value }));
        } else {
            const { name, value } = e.target as HTMLSelectElement;
            setSchoolDetails((prev) => ({ ...prev, [name]: value }));
        }
    }
    
    
    
      const[selectedSchool,setSchoolDetails]=useState(
        {
          school_id :0, udise_code:'', school_name:'', year_of_establishment:0, state_id:0, district_id:0, city_id:0, cluster_id:0, address:'', contact_no:'', email:'', location:'', instruction_medium:'', pre_primary_sectin_avilable:'', board_for_class_10th:'', board_for_class_12_plus:'', classes:'', total_teachers:'', contract_teachers:'', school_type:'', pre_primary_teachers:'', male_teachers:'', female_teachers:'', meal:'', school_area:'', school_shifted_to_new_place:'', head_teacher:'', is_school_residential:'', residential_type:'', management:'', building:'', class_rooms:'', boys_toilet:'', girls_toilet:'', computer_aided_learning:'', electricity:'', wall:'', library:'', playground:'', books_in_library:'', drinking_water:'', ramps_for_disable:'', computers:'', canteen:'', mid_day_meal:'', transport_facility:'', password:'', re_password:''
       ,image:''
       ,primary_teachers :0,
       middle_school_teachers :0,
       high_school_teachers :0
        });
    
        const [isSchoolInformationButtonClick,setSchoolInformationButtonClick]=useState<boolean>(false);
        const [isContactInformationButtonClick,setContactInformationButtonClick]=useState<boolean>(false);
        const [isSaveButtonClick,setSaveButtonClick]=useState<boolean>(false);
       
        
    
    const [schoolInformationShow,setShowSchoolInformation]=useState<boolean>(true);
    const [schoolContactShow,setShowSchoolContact]=useState<boolean>(false);
    const [resourcesShow,setShowResources]=useState<boolean>(false);
    const [facilityShow,setShowFacility]=useState<boolean>(false);
    const [loginInformationShow,setShowloginInformation]=useState<boolean>(false);
    const [progress, setProgress] = useState(0);
    
     
    
    const validateSchoolInformation=()=>
    {
      let validate=true;
      if(selectedSchool?.udise_code =='')
      {
        validate=false
      }
      if(selectedSchool?.school_name =='' && validate)
      {
        validate=false
      }
      if(selectedSchool?.instruction_medium =='' && validate)
      {
        validate=false
      }
      if((selectedSchool?.school_type =='Select' || selectedSchool?.school_type =='')  && validate)
      {
        validate=false
      }
      if((selectedSchool?.classes =='Select' || selectedSchool?.classes =='')  && validate)
      {
        validate=false
      }
      if((selectedSchool?.school_area =='Select' || selectedSchool?.school_area =='')  && validate)
      {
        validate=false
      }
      
      if((selectedSchool?.board_for_class_10th =='Select' || selectedSchool?.board_for_class_10th =='')  && validate)
      {
        validate=false
      }
      
       
    return validate;
    }
    
       const uploadImage = async () =>{
            debugger;
            if (file) {
                debugger;
                const formData = new FormData();
                formData.append('file', file);
                let date = new Date();
                formData.append('imageToSave', (selectedSchool.image));
                try {
    
                    debugger;
                    const response:any = await repository.uploadImage(formData);
                    debugger;
                    if (response.ok) {
                        debugger;
                        const responseData = await response.json();

                        navigate('/paymentprocess');


                       // navigate('/registrationsuccess');
                       // console.log('Image uploaded successfully:', responseData.imageUrl);
                      } else {
                        console.error('Failed to upload image. Server returned:', response.status);
                      }
                } catch (error) {
                  console.error('Error uploading image:', error);
                }
              }else{
                navigate('/registrationsuccess');
              }
            }
              
            
    
    
    const validateContactInformation=()=>
    {
      let validate=true;
      if(selectedSchool.state_id ==0)
      {
        validate=false
      }
      if(selectedSchool?.district_id ==0 && validate)
      {
        validate=false
      }
      if(selectedSchool?.city_id ==0 && validate)
      {
        validate=false
      }
      if((selectedSchool?.contact_no =='0' || selectedSchool?.contact_no=='') && validate)
      {
        validate=false
      }
    
      if((  selectedSchool?.address=='') && validate)
      {
        validate=false
      }
    
      
      if(selectedSchool?.cluster_id ==0 && validate)
      {
        validate=false
      }
     // checkSchoolContact(validate);
      return validate;
    }
    
    
    const  validateLoginInformation= () =>
    {
      debugger;
      let validate=true;
      if(selectedSchool.email =='')
      {
        validate=false
        
      }
      if(selectedSchool.email !='' && validate)
      {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      validate = emailRegex.test(selectedSchool.email);
      }
    
    
      if(selectedSchool?.password =='' && validate)
      {
        validate=false;
      }
      if(selectedSchool?.re_password =='' && validate)
      {
        validate=false;
      }
    
      if(selectedSchool?.password !=selectedSchool?.re_password && validate)
      {
        validate=false;
      }
      return   validate;
      // checkLoginInformatin(validate);
    }
    
    const schoolInformationNextClick = (event: React.MouseEvent)  => {
      event.preventDefault();
      setSchoolInformationButtonClick(true);
     const validate= validateSchoolInformation();
    if(validate)
    {
      setProgress(20);
    }
    setShowSchoolInformation(!validate);
    setShowSchoolContact (validate);
    };
    
    
    
    
    
    const contactInformationNextClick =   (event: React.MouseEvent)  => {
      event.preventDefault();
      setContactInformationButtonClick(true);
      const validate=  validateContactInformation();
      if(validate)
    {
      setProgress(40);
    }
      setShowSchoolContact(!validate);
    setShowResources(validate)
    
    }
    
    const resourceInformationNextClick = (event: React.MouseEvent)  => {
      event.preventDefault();
     
      setProgress(60);
    
      setShowResources(false);
      setShowFacility(true);
    }
    const facilityInformationNextClick = (event: React.MouseEvent)  => {
      debugger;
      event.preventDefault();
      setProgress(60);
      setShowFacility(false);
      setShowloginInformation(true);
    
    }
    
    const  saveClick=async( event: React.MouseEvent) =>
    {
    debugger;
      event.preventDefault();
      setSaveButtonClick(true);
      let date = new Date();
      selectedSchool.image=  (selectedSchool.udise_code + date.getTime().toString());
      selectedSchool.location=location;
      if(validateSchoolInformation() && validateContactInformation()  && validateLoginInformation())
      {
        try {
          debugger;
          
          const responseData = await repository.saveSchool(selectedSchool);
          if (responseData[0].db_result > 0) {
     


            dispatch({
              type: ActionType.STORE,
              payload: {
                storeData: {'order_id':'S-'+responseData[0].order_id,'amount':responseData[0].amount,'school_id':responseData[0].school_id},
                viewName: 'SchoolPaymentOrder',
              },
            });



            uploadImage();
           
            //  cancel();
             // getStates();
          }
          else if((+responseData[0].db_result)==-3)
          {
            
              Swal.fire({
                title: 'Exists!',
                text: 'UDISE Code or Email or School Name already registered with us .',
                icon: 'error',
                confirmButtonText: 'Okay',
              }).then((input:any)=>
              {

                navigate('/');
              });
             
          }
         // Swal.fire("Record saved successfully", "Record saved successfully", "success");
      } catch (error) {
      }
    
    
      }
    
    }
    

    return{lstClasses,schoolInformationShow,selectedSchool,isSchoolInformationButtonClick,handleChange,progress,schoolInformationNextClick
    ,schoolContactShow
    ,handleStateChange
    ,isContactInformationButtonClick
    ,handleCityChange
    ,handleClusterChange
    ,isSaveButtonClick
    ,contactInformationNextClick
    ,resourcesShow
    ,resourceInformationNextClick
    ,facilityShow
    ,facilityInformationNextClick
    ,loginInformationShow
    ,imageSrc
    ,handleImageChange
    ,handleImageError
    ,fileInputRef
    ,saveClick
    ,handleDistrictChange
    ,state_id
    ,
    district_id
    ,city_id
    ,handleCoordinatesChange
    ,coordinates
    ,handleBlur
    }
}