import Header from "../../components/Header/HeaderView";
import FooterView from "../Footer/FooterView";

const EducationalResources: React.FC = () => {

    return (
        <>
            <Header />
            <div className="container">
                <div className="row mt-5 mb-5">
                    <div className="col-md-12 school-features">
                        <h1><b>Educational Resources</b></h1>
                        <p >Welcome to the Educational Resources page of EducationTodayIndia.com! Here, you&#39;ll find a
                            comprehensive collection of resources to support teaching and learning across various subjects and
                            grade levels. From print titles to animated lessons, printable workbooks, lesson plans, and useful
                            links, we&#39;re committed to providing educators, parents, and students with the tools they need to
                            succeed in today&#39;s dynamic educational landscape.</p>

                        <b><h4 className="text-dark">Print Titles :</h4></b>
                        <p > Explore our curated selection of print titles covering a wide range of subjects, including
                            mathematics, science, language arts, social studies, and more. From textbooks to reference
                            materials, our print titles are designed to support classroom instruction and enhance students&#39;
                            understanding of key concepts.</p>

                        <b><h4 className="text-dark">Animated Lessons :</h4></b>
                        <p >Engage students with our interactive and visually stimulating animated lessons. Designed to make
                            learning fun and engaging, our animated lessons cover a variety of topics and are suitable for
                            learners of all ages. With captivating visuals and interactive elements, these lessons bring concepts
                            to life and help reinforce understanding.</p>

                        
                        <b><h4 className="text-dark">Printable Workbooks :</h4></b>
                        <p > Access our printable workbooks for additional practice and reinforcement of essential skills.
                            Whether students need extra practice in math, language arts, or science, our printable workbooks
                            offer a variety of exercises and activities to support learning both inside and outside the classroom.
                        </p>

                        
                        <b><h4 className="text-dark">Lesson Plans :</h4></b>
                        <p >Browse our collection of comprehensive lesson plans designed to streamline lesson preparation and
                            enhance instruction. Covering a wide range of subjects and topics, our lesson plans include
                            objectives, activities, assessments, and resources to support effective teaching and learning.</p>

                        
                        <b><h4 className="text-dark">Useful Links :</h4></b>
                        <p > Discover a wealth of useful links to educational websites, online resources, teaching tools, and more.
                            From interactive games and simulations to educational videos and tutorials, these links provide
                            additional support and enrichment opportunities for students and educators alike.</p>
                        <p >At EducationTodayIndia.com, we&#39;re dedicated to providing high-quality educational resources that
                            inspire learning, foster creativity, and promote academic success. Whether you&#39;re a teacher, parent,
                            or student, we invite you to explore our educational resources and unlock the power of learning
                            today!</p>

                    </div>
                </div>
            </div>
            <FooterView />
        </>
    )
}

export default EducationalResources;