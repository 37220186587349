import Header from "../../components/Header/HeaderView";
import AdmissionHelpMenuView from "../AdmissionHelpMenu/AdmissionHelpMenuView";
import { AdmissionSchoolSearchViewModel } from "./AdmissionSchoolSearchViewModel";


const AdmissionSchoolSearch: React.FC = () => {
    const srvm = AdmissionSchoolSearchViewModel();
    return (
        <>
            <Header />
            <AdmissionHelpMenuView/>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 mx-auto mt-5">
                        <div className="card brd-20">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-2 col-3 text-center">
                                        <img src="https://www.edustoke.com/new-ui/common/assistant/assets/img/edu_robo.svg" alt="" style={{ 'marginTop': '-60px' }} />
                                    </div>
                                    <div className="col-md-10 col-9">
                                        <h2 className="admission_heading">Hi, I am your Virtual Admission Advisor.</h2>
                                        <p>Walk us through requirements so that I can suggest the right school for your child!</p>
                                    </div>
                                    <div className="col-md-12 mx-auto mt-3">
                                        <hr />
                                    </div>



                                    {srvm.activeComponent == 'Type' ?
                                        <>
                                            <div className="col-md-12 text-center mt-2">
                                                <h3 className="text-capitalize m-0">Which type of school are you looking for?</h3>
                                                <p className="f-20 mt-2">Select any one</p>
                                            </div>
                                            {
                                                srvm.lstClassCategories && srvm.lstClassCategories.map((d: any, index: number) => (
                                                    <div className="col-md-3" onClick={(e) => srvm.selectCaegoryClass(d.ClassCategories)}>
                                                        <div className={d.selected == 'True' ? 'card brd-20 selected_school_type' : 'card brd-20'}>
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col-md-12 text-center">
                                                                        <img src="https://www.edustoke.com/new-ui/common/assistant/assets/img/day.svg" alt="" className="w-100 asp-1" />
                                                                        <b className="f-18">{d.ClassCategories}</b>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </> : ''
                                    }


                                    {/* 2 */}

                                    {
                                        srvm.activeComponent == 'Class' ?
                                            <div className="col-md-12" style={{ 'minHeight': '300px' }}>
                                                <div className="row">
                                                    <div className="col-md-12 text-center mt-2">
                                                        <h3 className="text-capitalize m-0">
                                                            You are looking for admission in a <span className="primary-text-danger">
                                                                {srvm.lstSelected.Type}
                                                            </span> Which class do you want admission in?
                                                        </h3>
                                                        <p className="f-20 mt-2">Select any one</p>
                                                    </div>
                                                    <div className="col-md-12 text-center">
                                                        {srvm.lstClasses && srvm.lstClasses.map((d: any, index: number) => (
                                                            <button key={index} onClick={(e) => srvm.selectClass(d.classes)} className={d.selected === 'True' ? 'selection_btn btn selected-btn btn-rounded mx-2 mb-2' : 'selection_btn btn btn-outline-dark btn-rounded mx-2 mb-2'}>{d.classes} </button>
                                                        ))}
                                                        {/* <button className="selection_btn btn selected-btn btn-rounded mx-2 mb-2">Pre Nursery</button>
                                                <button className="selection_btn btn btn-outline-dark btn-rounded mx-2 mb-2">Nursery</button> */}
                                                    </div>
                                                </div>
                                            </div> : ''
                                    }


                                    {/* 3 */}
                                    {
                                        srvm.activeComponent == 'Fees' ?
                                            <div className="col-md-12" style={{ 'minHeight': '300px' }}>
                                                <div className="row">
                                                    <div className="col-md-12 text-center mt-2">
                                                        <h3 className="text-capitalize m-0 heading-2">
                                                            You are looking for admission in a <span className="primary-text-danger">{srvm.lstSelected.Type}</span> for grade <span className="primary-text-danger">{srvm.lstSelected.Class}</span> What is the annual fee range you are looking for?
                                                        </h3>
                                                        <p className="f-20 mt-2">You can select multiple</p>
                                                    </div>

                                                    <div className="col-md-12 text-center">
                                                        {
                                                            srvm.lstFees && srvm.lstFees.map((d: any, index: number) => (
                                                                <button key={index} onClick={(e) => srvm.selectFees(d, index)} className={d.Selected == 'True' ? 'selection_btn btn selected-btn btn-rounded mx-2 mb-2' : 'selection_btn btn btn-outline-dark btn-rounded mx-2 mb-2'}>{d.Fees}</button>
                                                            ))
                                                        }

                                                    </div>
                                                </div>
                                            </div> : ''
                                    }

                                    {/* 4 */}
                                    {
                                        srvm.activeComponent == 'Board' ?
                                            <div className="col-md-12" style={{ 'minHeight': '300px' }}>
                                                <div className="row">
                                                    <div className="col-md-12 text-center mt-2">
                                                        <h3 className="text-capitalize m-0 heading-2">
                                                            You are looking for admission in a <span className="primary-text-danger">Day School</span> for grade <span className="primary-text-danger">Nursery</span> in the fee range <span className="primary-text-danger">50K to 70K,70k to 1Lac,1 Lac to 2 Lac</span> What is your preference for the Board?
                                                        </h3>
                                                        <p className="f-20 mt-2">You can select multiple</p>
                                                    </div>
                                                    <div className="col-md-12 text-center">
                                                        {
                                                            srvm.lstBoard && srvm.lstBoard.map((d: any, index: number) => (
                                                                <button key={index} onClick={(e) => srvm.selectBoard(d, index)} className={d.Selected == 'True' ? 'selection_btn btn selected-btn btn-rounded mx-2 mb-2' : 'selection_btn btn btn-outline-dark btn-rounded mx-2 mb-2'}>{d.Board}</button>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            : ''
                                    }

                                    {/* 5 */}

                                    {
                                        srvm.activeComponent == 'Details' ?
                                            <div className="col-md-12" style={{ 'minHeight': '300px' }}>
                                                <div className="row">
                                                    <div className="col-md-12 text-center mt-2">
                                                        <h3 className="text-capitalize m-0 heading-2">
                                                            Check the information you have provided so that we can suggest the best schools.
                                                        </h3>
                                                        <p className="f-20 mt-2">You are looking for admission in</p>
                                                    </div>

                                                    <div className="col-md-3 text-center">
                                                        <p className="f-18 text-center text-dark m-0"><b>Type</b></p> <br />
                                                        <button className="selection_btn btn selected-btn btn-rounded mx-2 mb-2 w-100">{srvm.lstSelected.Type}</button>
                                                    </div>

                                                    <div className="col-md-3 text-center">
                                                        <p className="f-18 text-center text-dark m-0"><b>Class</b></p> <br />
                                                        <button className="selection_btn btn selected-btn btn-rounded mx-2 mb-2 w-100">{srvm.lstSelected.Class}</button>
                                                    </div>

                                                    <div className="col-md-3 text-center">
                                                        <p className="f-18 text-center text-dark m-0"><b>Fees</b></p> <br />
                                                        {srvm.lstSelected.Fees && srvm.lstSelected.Fees.map((d: any, index: number) => (
                                                            <button className="selection_btn btn selected-btn btn-rounded mx-2 mb-2 w-100">{d.Fees}</button>
                                                        ))}
                                                    </div>

                                                    <div className="col-md-3 text-center">
                                                        <p className="f-18 text-center text-dark m-0"><b>Board</b></p> <br />
                                                        {srvm.lstSelected.Board && srvm.lstSelected.Board.map((d: any, index: number) => (
                                                            <button key={index} className="selection_btn btn selected-btn btn-rounded mx-2 mb-2 w-100">{d.Board}</button>
                                                        ))}
                                                    </div>

                                                </div>
                                            </div>
                                            : ''
                                    }

                                    {/* 6 */}
                                    {
                                        srvm.activeComponent == 'Contact' ?
                                            <div className="col-md-12" style={{ 'minHeight': '300px' }}>
                                                <div className="row">
                                                    <div className="col-md-12 text-center mt-2">
                                                        <h3 className="text-capitalize m-0 heading-2">
                                                            That’s great! We have found the best schools as per the info you provided.
                                                        </h3>
                                                        <p className="f-20 mt-2">Fill in the following information to <span className="primary-text-danger">GET IN TOUCH!</span></p>
                                                    </div>

                                                    <div className="col-md-4 mb-3">
                                                        <label htmlFor="">Full Name</label>
                                                        <input type="text" className="form-control" placeholder="Enter Your Full Name" 
                                                        name="full_name" value={srvm.contact.full_name} onChange={srvm.handleChange}/>
                                                        { srvm.formSubmit == true && srvm.contact.full_name == '' ? <label className="primary-text-danger">Required!</label> : ''}
                                                    </div>

                                                    <div className="col-md-4 mb-3">
                                                        <label htmlFor="">Email</label>
                                                        <input type="email" className="form-control" pattern="^[^\s@]+@[^\s@]+\.[^\s@]{2,}$" placeholder="Enter Your Email Address" name="email" value={srvm.contact.email} 
                                                        onChange={srvm.handleChange}/>
                                                        { srvm.formSubmit == true && srvm.contact.email == '' ? <label className="primary-text-danger">Required!</label> : ''}
                                                        { srvm.contact.email !== '' && !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(srvm.contact.email) && <label className="primary-text-danger">Invalid email format!</label> }
                                                    </div>

                                                    <div className="col-md-4 mb-3">
                                                        <label htmlFor="">Mobile Number</label>
                                                        <div className="input-group ">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text" id="basic-addon1">
                                                                    <div className="dropdown">
                                                                        <button className="btn dropdown-toggle p-0" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                            +91
                                                                        </button>
                                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                            <a className="dropdown-item" href="#">India +12</a>
                                                                            <a className="dropdown-item" href="#">USA +15</a>
                                                                            <a className="dropdown-item" href="#">UK +14</a>
                                                                        </div>
                                                                    </div>
                                                                </span>
                                                            </div>
                                                            <input type="number" className="form-control" placeholder="Enter Mobile Number" name="mobile_number" value={srvm.contact.mobile_number} onChange={srvm.handleChange}/>
                                                        </div>
                                                        { srvm.formSubmit == true && srvm.contact.mobile_number == '' ? <label className="primary-text-danger">Required!</label> : ''}
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label htmlFor="">Enter Your Preferred Location</label>
                                                        <input type="text" className="form-control" placeholder="Type a Location or School Name" name="location" value={srvm.contact.location} onChange={srvm.handleChange}/>
                                                        { srvm.formSubmit == true && srvm.contact.location == '' ? <label className="primary-text-danger">Required!</label> : ''}
                                                    </div>
                                                    {/* <div className="col-md-12 mt-2">
                                                        <span><input type="checkbox" name="" id="" /> I wish to receive admission updates and offers from schools</span>
                                                    </div> */}
                                                </div>
                                            </div>
                                            : ''
                                    }
                                    <div className="col-md-12">
                                        <hr />
                                    </div>

                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-5 mb-2">

                                                <b>1 of 5</b>
                                                <div className="progress">

                                                    <div className="progress-bar"
                                                        role="progressbar"
                                                        style={{
                                                            width:
                                                                srvm.activeComponent == 'Type' ? '0%' :
                                                                srvm.activeComponent == 'Class' ? '20%' :
                                                                srvm.activeComponent == 'Fees' ? '40%' :
                                                                srvm.activeComponent == 'Board' ? '60%' :
                                                                srvm.activeComponent == 'Details' ? '80%' :
                                                                srvm.activeComponent == 'Contact' ? '100%' : '0%'
                                                        }}
                                                        aria-valuemin={0}
                                                        aria-valuemax={100} ></div>
                                                </div>
                                            </div>

                                            <div className="col-md-7 text-end my-auto">
                                                {srvm.activeComponent != 'Type' && srvm.activeComponent != 'Contact' ? 
                                                <button className="btn btn-prev w-120 b-r-50 text-light" onClick={srvm.prev}> &lt; Prev </button> : ''}
                                                
                                                { srvm.activeComponent != 'Contact' ? 
                                                <button className="btn btn-next w-120 b-r-50 text-light mx-3" onClick={srvm.next}>Next &gt; </button> :
                                                <button className="btn btn-next w-120 b-r-50 text-light mx-3" onClick={srvm.submit}>Submit</button> }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default AdmissionSchoolSearch;