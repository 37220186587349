import { useNavigate } from "react-router";
import Header from "../../components/Header/HeaderView";
import FooterView from "../Footer/FooterView";

const Pricing: React.FC = () => {
    const navigate = useNavigate();
    return (
        <>
            <Header />
            <div className="container">
                <div className="row mt-5 mb-5">
                    <div className="col-md-12 school-features">
                    <embed src= "./img/docs/rate-card.pdf" width= "100%" height= "1020"/>
                    </div>
                    <div className="col-md-12 text-end">
                    <button className="eti-btn-success mx-3"  onClick={()=> {navigate('/schoolregistration')}}>
                                Register
                            </button>
                    </div>
                </div>
            </div>
            <FooterView />
        </>
    )
}

export default Pricing;