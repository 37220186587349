import Footer from "../../components/Footer/FooterView";
import Header from "../../components/Header/HeaderView";
import { EduNewsViewModel } from "./EduNewsViewModel";

 

const  EduNewsListView:React.FC =()=>
{
const viewModel=EduNewsViewModel();
    return (
        <>
        <Header/>
    <div className="container mt-5">

    <div className="row">

        <div className="col-md-9">

        {
                        viewModel.eduFeedItems.map((item, i) => (


            <div className="row mb-4 cursor-p"  key={i} onClick={(e)=>viewModel.openFeedPage(item.link)}>

                <div className="col-md-5">

                    <img src={item.enclosure["@attributes"].url} alt={item.title} className="w-100"/>

                </div>

                <div className="col-md-7 m-auto">

                    <h4>{item.title}</h4>

                    <h4 className="mb-4 text-capitalize fw-400">{item.description.split('</a>')[1]}</h4>

                    {/* <p className="m-0">By Daniel Mollenkamp</p> */}

                </div>

            </div>

                     ) )  }


        </div>



       

    </div>

</div>
<Footer/>
</>
)
}

export default EduNewsListView;