import Header from "../../components/Header/HeaderView";
import FooterView from "../Footer/FooterView";

const TermsOfService: React.FC = () => {

    return (
        <>
            <Header />
            <div className="container">
                <div className="row mt-5 mb-5">
                    <div className="col-md-12 school-features">
                        <h1><b>Terms of Service for EducationTodayIndia.com</b></h1>
                        <p>Please read these Terms of Service (&quot;Terms&quot;) carefully before using the EducationTodayIndia.com
website (the &quot;Service&quot;) operated by [EducationTodayIndia] (&quot;us&quot;, &quot;we&quot;, or &quot;our&quot;).</p>


<p>By accessing or using the Service, you agree to be bound by these Terms. If you disagree with any
part of the terms, then you may not access the Service.</p>

                        <b><h4 className="text-dark">1. Use of Service :</h4></b>
                        <p>- You must be at least 13 years old to use the Service.</p>

                        <p>You are responsible for your use of the Service and for any content you post or share on the website.</p>
                        <p>- You may not use the Service for any illegal or unauthorized purpose.</p>

                        <b><h4 className="text-dark">2. Intellectual Property Rights :</h4></b>
                        <p > - The content, features, and functionality of the Service are owned by [EducationTodayIndia] and are
protected by copyright, trademark, and other intellectual property laws.
                        </p>

                        <p > - You may not modify, reproduce, distribute, or exploit any part of the Service without our prior
written consent.
                        </p>


                        <b><h4 className="text-dark">3. User Content :</h4></b>
                        <p >- You retain ownership of any content you post or share on the Service.</p>

                        <p>- By posting content on the Service, you grant us a non-exclusive, royalty-free, perpetual, irrevocable, and fully sub-licensable right to use, reproduce, modify, adapt, publish, translate, create
derivative works from, distribute, and display such content worldwide.</p>

<p>- You represent and warrant that you have the necessary rights to grant us the above license and
that your content does not violate any third party rights or applicable laws.</p>

                        <b><h4 className="text-dark">4. Privacy Policy :</h4></b>
                        <p >- Your use of the Service is subject to our Privacy Policy, which governs the collection, use, and
disclosure of your personal information.</p>
                        <b><h4 className="text-dark">5. Termination :</h4></b>
                        <p >- We may terminate or suspend your access to the Service immediately, without prior notice or
liability, for any reason whatsoever, including without limitation if you breach these Terms.</p>
<p>- Upon termination, your right to use the Service will cease immediately.</p>

                        <b><h4 className="text-dark">6. Limitation of Liability :</h4></b>
                        <p >- In no event shall Education Today, nor its owners, employees, partners, agents, suppliers, or
affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including
without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your
access to or use of or inability to access or use the Service.</p>

                        <b><h4 className="text-dark">7. Governing Law :</h4></b>
                        <p >- These Terms shall be governed and construed in accordance with the laws of Telangana, India,
without regard to its conflict of law provisions.</p>

                        <p>- Our failure to enforce any right or provision of these Terms will not be considered a waiver of those
rights.</p>




<b><h4 className="text-dark">8. Changes :</h4></b>
                        <p >- We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a
revision is material, we will provide at least 30 days&#39; notice prior to any new terms taking effect.</p>

                        <p>- By continuing to access or use our Service after any revisions become effective, you agree to be
bound by the revised terms.</p>





<b><h4 className="text-dark">9. Contact Us :</h4></b>
                        <p >- If you have any questions about these Terms, please contact us at
support@educationtodayindia.com.</p>

                        <p>By accessing or using the Service, you agree to be bound by these Terms. If you do not agree to
these Terms, please do not use the Service.</p>


                    </div>
                </div>
            </div>
            <FooterView />
        </>
    )
}

export default TermsOfService;