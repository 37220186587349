import { useEffect, useState } from "react";
import { SchoolRepository } from "../../repository/SchoolRepository";

export function DistrictsViewModel(onDistrictChange:any,state_id:any)
{


    const repository =SchoolRepository();
    const [selectedOption, setSelectedOption] = useState<{ label: string; value: string } | null>(null);
    const [allOptions, setAllOptions] = useState<{ label: string; value: string }[]|[]>([]);
    //const [optionsLoaded, setOptionsLoaded] = useState<boolean>(false);
 
    useEffect(() => {

        if( typeof(state_id)=='undefined' || state_id==0   )
         {
            setAllOptions([]);
         }
     
      const fetchAllOptions = async () => {
        try {
          debugger;
  
         if( typeof(state_id)!='undefined' )
         {
          
       //   setOptionsLoaded(false);
         
          const data:any = await repository.viewDistricts(state_id);// response.json();
  debugger ;
        
          const options: { label: string; value: string }[] = data.map((item: any) => ({
            label: item.district_name,
            value: item.district_id.toString(),
          }));
  
          setAllOptions(options);
      //    setOptionsLoaded(true);
        }
        } catch (error) {
          console.error('Error fetching all options:', error);
        }
      };
      fetchAllOptions();
    }, [state_id]); 
  
    const filterOptions = (inputValue: string) => {
      debugger;
  
      if(inputValue!='')
      {
        return  allOptions.filter((option) =>
      {
        return option.label.toLowerCase().includes(inputValue.toLowerCase());
        
      }
       
      );
    }else{
  
      return allOptions;
  
    }
    };
  
    const handleSelectChange = (selectedOption: { value: string; label: string } | null) => {
      debugger;
       
      setSelectedOption(selectedOption);
      onDistrictChange(selectedOption?(+selectedOption.value):0);
      console.log('Selected Option:', selectedOption);
      
    };
  
    const loadOptions = (inputValue: string, callback: (options: { label: string; value: string }[]) => void) => {
      debugger;
     
      const filteredOptions = filterOptions(inputValue);
      callback(filteredOptions);
    };




    return {selectedOption,handleSelectChange,loadOptions,allOptions}
}