import Header from "../../components/Header/HeaderView";
import FooterView from "../Footer/FooterView";
import { B2BHomeModel } from "./B2BHomeModel";

const B2BHome: React.FC = () => {
    const viewModel= B2BHomeModel();
    return (
        <>
            <Header />
            <div className="container-fluid drd-bg-success p-0">
            <div className="row">
                <div className="col-md-12 head-image">
                    <img src="./img/images/back-to-school.JPG" alt=""/>
                </div>
            </div>
        </div>


        <div className="container ">
            <div className="row">
                <div className="col-md-6 ">
                    <h1 className="mt-5 mb-3 fw-600 large-title" style={{'paddingBottom':'15px'}}>Enlist Your School</h1>
                    <p className="medium-text">Unlock Success with School Listing:<br/>
                        A Strategic Gateway to Optimize Visibility,<br/>
                        Attract the Right Audience, and Drive<br/>
                        Successful Admissions</p>
                    <div className="learn-buttons">
                        <button className="eti-btn-success"  onClick={()=> viewModel.onRegisterClick()}>
                            Learn More
                        </button>
                        <button className="eti-btn-success mx-3" onClick={()=> viewModel.onRegisterClick()}>
                            Register
                        </button>
                    </div>
                </div>
                <div className="col-md-6 mt-3">
                    <img src="./img/images/Computer 2.png" alt="" className="w-100"/>
                </div>

            </div>
        </div>
        <div className="grad1">
            <div className="container">
                <div className="row ">
                    <div className="col-md-6 ">
                        <img src="./img/images/Kit.png" alt="" className="w-100"/>
                    </div>
                    <div className="col-md-6 ">
                        <section>
                            <h1 className="mb-0 mt-5 fw-600 large-title" style={{'paddingBottom': '15px', 'textAlign': 'center'}}> School Stores</h1>
                            <p className="medium-text"> Enhance the parent and student experience with a
                                customized school essentials store on our platform.</p>

                            <p className="medium-text"> Enjoy seamless shopping, making access to
                                necessary items convenient.</p>

                            <p className="medium-text"> Partner with us in creating an  efficient shopping
                                environment for your school community.</p>
                        </section>
                        <div className="school-buttons">
                            <button className="eti-btn-orange"  onClick={()=> viewModel.onRegisterClick()}>
                                Get Started
                            </button>
                        </div>
                    </div>
                </div>

                <div className="row py-auto">
                    <div className="col-6 accessories-items">
                        <div className="row">
                            <div className="col-md-4 ">
                                <img src="./img/images/Kit 2.png" alt="" className="w-100"/>
                                <h4>Back to School Kits</h4>
                            </div>
                            <div className="col-md-4 ">
                                <img src="./img/images/uniforms.png" alt="" className="w-100"/>
                                <h4>Uniforms</h4>
                            </div>
                            <div className="col-md-4 ">
                                <img src="./img/images/Books.png" alt="" className="w-100"/>
                                <h4>Books</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 accessories-items">
                        <div className="row">
                            <div className="col-md-4 ">
                                <img src="./img/images/Stationery.png" alt="" className="w-100"/>
                                <h4>Stationery</h4>
                            </div>
                            <div className="col-md-4 ">
                                <img src="./img/images/5.png" alt="" className="w-100"/>
                                <h4> Memorabilia</h4>
                            </div>
                            <div className="col-md-4 ">
                                <img src="./img/images/6.png" alt="" className="w-100"/>
                                <h4> Toys</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid mb-3 web-school_branding">
            <div className="row ">
                <div className="col-md-6 my-auto" >
                    <section className="tablet">
                        <h1 className="mb-3 mt-5 fw-600 large-title" style={{'paddingBottom': '15px'}}> School Branding</h1>
                        <p className="medium-text">Is Your School Missing the Digital Mark?<br/>
                            Overcome Challenges in Web Presence, Public<br/>
                            Relations, Social Media Marketing, and<br/>
                            Branding Excellence for your school.</p>


                        <div className="pricing-buttons">
                            <button className="eti-btn-success"  onClick={()=>{ viewModel.pricingClick()}}>
                                Pricing
                            </button>
                            <button className="eti-btn-success mx-3"  onClick={()=> viewModel.onRegisterClick()}>
                                Register
                            </button>
                        </div>
                    </section>
                </div>
               
            </div>
        </div>


        <div className="container mobile-school_branding">
            <div className="row" >
                <div className="col-md-6 ">
                    <section className="tablet">
                        <h1 className="mb-3 mt-5 fw-600 "> School Branding</h1>
                        <p className="medium-text">Is Your School Missing the Digital Mark?
                            Overcome Challenges in Web Presence, Public
                            Relations, Social Media Marketing, and
                            Branding Excellence for your school.</p>


                        <div className="pricing-buttons">
                            <button className="eti-btn-success">
                                Pricing
                            </button>
                            <button className="eti-btn-success">
                                Register
                            </button>
                        </div>
                    </section>
                </div>
                <div className="col-md-6">
                <img src="./img/images/tablet.png" className="w-100" />
            </div>
            </div>
        </div>

        <FooterView/>
            </>
    )

}

export default B2BHome;