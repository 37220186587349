export function SchoolRepository() {

  const api = 'https://educationtodayindia.com/educationtodayindiaschoolwebapi/api/';
  const api1 = 'https://api.educationtodayindia.com/educationtodayindiaschoolwebapi/api/';
  const imagePath = "https://educationtodayindia.com/educationtodayindiaschoolwebapi/public/images/"

  // const api='https://educationtodayindia.com/testing/webapi/api/';
  // const imagePath="https://educationtodayindia.com/testing/webapi/public/images/"
  const viewCities = async (district_id: number) => {
    $("#loaderParent").show();
    try {
      const response = await fetch(api + 'getcitiesbydistrict', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',

        },
        body: JSON.stringify({ district_id: district_id }),
      }
      );

      const data = await response.json()
      $("#loaderParent").hide();
      return data;
    } catch (e) {
      $("#loaderParent").hide();
    }
  }

  const viewSchoolFees=async (SchoolId:any) => {
    $("#loaderParent").show();
    try {
    const response = await fetch(api1 + 'viewschoolfees', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body:JSON.stringify(SchoolId)
    })
    const responseData = await response.json();
debugger;
    $("#loaderParent").hide(); 
    return responseData;


}
catch (error) {
    $("#loaderParent").hide();
}

   }


  const updateOrderPaymentStatus=async(rsponseData:any) =>
  {
  
    $("#loaderParent").show();
    try{
   // if (isLoading) {
     // return <div>Loading...</div>;
   // }
    const response = await fetch(api+'updateorderpaymentstatus', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Add any other headers as needed
      },
      body: (rsponseData),
    }
      );
    const data:any  = await response.json();
    
    $("#loaderParent").hide();
    return  data;
  }catch(e)
  {
    $("#loaderParent").hide();
  }
  }
   
  const getClassesForRegistration = async () => {
    try {
      $("#loaderParent").show();
      const response = await fetch(api + 'getclassesforschoolregistration');
      const data = await response.json();
      $("#loaderParent").hide();
      return data;
    } catch (e) {
      $("#loaderParent").hide();
    }
  }


  const loadCities = async () => {
    try {
      $("#loaderParent").show();
      const response = await fetch(api + 'getcities');
      const data = await response.json();
      $("#loaderParent").hide();
      return data;
    } catch (e) {
      $("#loaderParent").hide();
    }
  }
  const getContents = async (Content: any) => {
    $("#loaderParent").show();
    try {
      debugger;
      const response = await fetch(api + 'getcontent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(Content),
      })
      debugger;
      const responseData = await response.json();
      $("#loaderParent").hide(); return responseData;
    }
    catch (error) {
      $("#loaderParent").hide();
    }
  }



  const viewClusters = async (city_id: number) => {
    try {
      $("#loaderParent").show();
      const response = await fetch(api + 'getclustersbycity', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers as needed
        },
        body: JSON.stringify({ city_id: city_id }),
      }
      );
      const data = await response.json()
      $("#loaderParent").hide();
      return data;
    } catch (e) {
      $("#loaderParent").hide();
    }
  }

  const viewStates = async () => {
    try {
      $("#loaderParent").show();
      const response = await fetch(api + 'getstates');
      const data = await response.json();
      $("#loaderParent").hide();
      return data;
    } catch (e) {
      $("#loaderParent").hide();
    }

  }

  const viewDistricts = async (state_id: any) => {
    try {
      $("#loaderParent").show();
      const response = await fetch(api + 'getdistrictsbystate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers as needed
        },
        body: JSON.stringify({ state_id: state_id }),
      }
      );
      const data = await response.json();
      $("#loaderParent").hide();
      return data;
    } catch (e) {
      $("#loaderParent").hide();
    }

  }


  const getSchoolResource = async (school_id: number) => {
    try {
      $("#loaderParent").show();
      const response = await fetch(api + 'viewschoolresources', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers as needed
        },
        body: JSON.stringify({ school_id: school_id }),
      }
      );
      const data = await response.json()
      $("#loaderParent").hide();
      return data;
    } catch (e) {
      $("#loaderParent").hide();
    }
  }

  const fetchRssFeed = async () => {
    debugger;
    try {
      $("#loaderParent").show();
      const response: any = await fetch(api + 'rssfeed');
      const data: any = await response.text();
      debugger;
      $("#loaderParent").hide();
      return data;
    } catch (e) {
      $("#loaderParent").hide();
    }
  }



  const uploadImage = async (formData: any) => {
    $("#loaderParent").show();
    try {
      const response: any = await fetch(api + 'upload', {
        method: 'POST',
        body: formData,
      });
      $("#loaderParent").hide();
      return response;
    } catch (e) {
      $("#loaderParent").hide();
    }
  }
  const saveSchool = async (selectedSchool: any) => {
    try {
      $("#loaderParent").show();
      const response = await fetch(api + 'saveschool', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(selectedSchool)
      })
      const responseData = await response.json();
      $("#loaderParent").hide();
      return responseData;
    } catch (e) {
      $("#loaderParent").hide();
    }
  }
  const getAnnouncements = async (Announcemnts: any) => {
    $("#loaderParent").show();
    try {

      debugger;
      const response = await fetch(api + 'getannouncements', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(Announcemnts),
      })
      debugger;
      const responseData = await response.json();
      $("#loaderParent").hide();
      return responseData;
    }
    catch (error) {
      $("#loaderParent").hide();

    }
  }

  const searchSchools = async (searchData: any) => {

    $("#loaderParent").show();
    try {
      // if (isLoading) {
      // return <div>Loading...</div>;
      // }
      const response = await fetch(api + 'searchschools', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers as needed
        },
        body: JSON.stringify(searchData),
      }
      );
      const data: any = await response.json();
      $("#loaderParent").hide();
      return data;
    } catch (e) {
      $("#loaderParent").hide();
    }
  }


  const getClassCategories = async (searchData: any) => {
    $("#loaderParent").show();
    try {
      const response = await fetch(api1 + 'getclasscategories', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        // body: JSON.stringify(searchData),
      }
      );
      const data: any = await response.json();
      $("#loaderParent").hide();
      return data;
    } catch (e) {
      $("#loaderParent").hide();
    }
  }


  const getclassesfromclasscategory = async (data: any) => {
    $("#loaderParent").show();
    try {
      debugger;
      const response = await fetch(api1 + 'getclassesfromclasscategory', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      debugger;
      const responseData = await response.json();
      $("#loaderParent").hide(); return responseData;
    }
    catch (error) {
      $("#loaderParent").hide();
    }
  }


  const saveAdmissionHelp = async (data: any) => {
    $("#loaderParent").show();
    try {
      debugger;
      const response = await fetch(api1 + 'saveadmissionhelp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      debugger;
      const responseData = await response.json();
      $("#loaderParent").hide(); return responseData;
    }
    catch (error) {
      $("#loaderParent").hide();
    }
  }


  return { viewSchoolFees,updateOrderPaymentStatus,getClassesForRegistration, getContents, imagePath, viewCities, viewClusters, viewStates, getSchoolResource, viewDistricts, fetchRssFeed, loadCities, uploadImage, saveSchool, searchSchools, getAnnouncements ,
    getClassCategories,getclassesfromclasscategory,saveAdmissionHelp};

}