import Header from "../../components/Header/HeaderView";
import FooterView from "../Footer/FooterView";

const CaringEnvironment: React.FC = () => {

    return (
        <>
            <Header />
            <div className="container">
                <div className="row mt-5 mb-5">
                    <div className="col-md-12 school-features">
                        <h1><b>Caring Environment:</b></h1>
                        <h4>A Message from Education Today for Children</h4>
                        <strong>Dear Young Learners,</strong>

                        <p >At Education Today, we believe that every child deserves to learn and grow in a caring and nurturing
                            environment. We understand that school is not just a place for academics, but also a home away
                            from home where you feel loved, supported, and valued. That&#39;s why we&#39;re dedicated to creating a
                            learning environment where you can thrive, explore, and discover the joy of learning.</p>

                        <p >In our caring environment, kindness is our language, and compassion is our guiding principle. We
                            celebrate diversity and embrace each other&#39;s differences, knowing that it&#39;s what makes us unique
                            and special. Whether you&#39;re a budding scientist, aspiring artist, or future leader, you are welcome
                            here, just as you are.</p>

                        <p >We want you to know that your well-being is our top priority. Our teachers and staff are here to
                            support you every step of the way, whether you need help with your studies, someone to talk to, or
                            a shoulder to lean on. We&#39;re here to listen, encourage, and empower you to reach for the stars and
                            achieve your dreams.</p>

                        <p > In our caring environment, we believe in the power of positivity and the importance of fostering
                            meaningful connections with others. We encourage you to be kind to yourself and to those around
                            you, to lend a helping hand to those in need, and to always strive to make a positive difference in
                            the world.
                        </p>
                        <p >So, dear young learners, as you embark on your educational journey with Education Today,
                            remember that you are loved, valued, and cherished. You are capable of achieving great things, and
                            we are here to cheer you on every step of the way. Together, let&#39;s create a world where kindness
                            reigns supreme, and every child has the opportunity to thrive and succeed.</p>

                        <p > With love and care,</p>

                        <strong>Education Today</strong>
                    </div>
                </div>
            </div>
            <FooterView />
        </>
    )
}

export default CaringEnvironment;