import Modal from 'react-modal';
import  LightboxProps, {
}from  "./LightboxViewModel";
const LightboxView:React.FC<LightboxProps> = ({ isOpen, onClose, imageURLs, currentIndex, onPrev, onNext, caption }) => {


    return (
        <Modal
          isOpen={isOpen}
          onRequestClose={onClose}
          style={{
            content: {
              maxWidth: '800px',
              margin: 'auto',
              height: 'max-content',
              maxHeight: '800px',
              objectFit:'contain'
              
            },overlay: {
                backgroundColor: "rgb(3 3 3 / 75%)",
                zIndex:999
              },
          }}
        >
            <span className="close cursor" onClick={onClose}>&times;</span>
          <div>
            
     
                                                     
                                                      
                                                  
                                                      <div >
                                                      
                                                        <img className="w-100" 
                                                        alt={caption}
                                                        src={imageURLs[currentIndex]}
                                                        
                                                        />
                                                      </div>
                                                      
                                                  
                                                     
                                                      
                                                      <a className="prev"  onClick={onPrev}>&#10094;</a>
                                                      <a className="next" onClick={onNext}>&#10095;</a>
                                                  
                                                      {/* <div className="caption-container">
                                                        <p id="caption"></p>
                                                      </div> */}
                                                    </div>
                                                  
    
           
        </Modal>
      );

}
export default LightboxView;