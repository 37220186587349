import React, { useEffect, useState } from "react";
import AsyncSelect from 'react-select/async';
import { StateViewModel } from "./StateViewModel";
interface ChildProps {
  onStateChange: (state_id: number) => void;
}


const StatesView:React.FC<ChildProps>=({onStateChange} )=>
{

  const viewModel=StateViewModel(onStateChange);

  return (
    <>
    
      {viewModel.optionsLoaded  && (
    <AsyncSelect
    defaultOptions
      isClearable
      isSearchable
      value={viewModel.selectedOption}
      onChange={viewModel.handleSelectChange}
      loadOptions={viewModel.loadOptions}
    />
    )}
    </>
  );
}
export default StatesView;