import { useNavigate } from "react-router";

export function AdmissionHelpMenuViewModel()
{
    const navigate = useNavigate();
const listingClick =() => {


  
    navigate('/statelist');

}


const admissionHelp = () =>{
    navigate('/admission-school-search')
}

    return{listingClick,admissionHelp}
}