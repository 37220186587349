import Header from "../../components/Header/HeaderView";
import FooterView from "../Footer/FooterView";

const Advertise: React.FC = () => {

    return (
        <>
            <Header />
            <div className="container">
                <div className="row mt-5 mb-5">
                    <div className="col-md-12 school-features">
                    <embed src= "./img/docs/rate-card.pdf" width= "100%" height= "1020"/>
                    </div>
                </div>
            </div>
            <FooterView />
        </>
    )
}

export default Advertise;