import React from "react";
import {StateListViewModel} from "./StateListViewModel";
import Header from "../../components/Header/HeaderView";
import Footer from "../../components/Footer/FooterView";
import AdmissionHelpMenu from "../../components/AdmissionHelpMenu/AdmissionHelpMenuView";
const StateListView:React.FC=()=>
{
    const viewModel=StateListViewModel();

return (
    <>
        <Header/>
        <AdmissionHelpMenu/>
        <section className="bg-gray p-3">
<div className="container mt-4 h-a mb-5"  >
<div className="text-center my-4"><h2 className="mb-1">List of Schools in India </h2> Boarding, CBSE, ICSE, Govt. and Private Schools in India. </div>
<div className="row h-a"  >
 <div className="col-md-12 mt-2"> 
<div className="row">   

{
    viewModel.lstStates.map((item:any,i)=>(
<div className="col-md-3 mt-2"> 
<div className="card-columns col-md" >
<a onClick={()=>viewModel.stateClick(item)}>
<div className="card act bg-shdw" style={{height: '125px'}}>
<div className="card-body text-center">
<h4 className="card-title text-center d-flex txt-color justify-content-center"><img src="https://cdn-icons-png.flaticon.com/256/0/619.png" 
style={{width: '20px',height: '20px'}}/>{item.state_name}</h4>
<p className="card-text detail-color">Schools in {item.state_name}</p>
</div>
</div> 
</a>
</div>
</div>

    ))
}


   
</div>
</div>  
</div>  
</div>
</section>
<Footer/>
</>
 )

}
export default StateListView;
