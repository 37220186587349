import Header from "../../components/Header/HeaderView";
import FooterView from "../Footer/FooterView";

const PrivacyPolicy: React.FC = () => {

    return (
        <>
            <Header />
            <div className="container">
                <div className="row mt-5 mb-5">
                    <div className="col-md-12 school-features">
                        <h1><b>Privacy Policy for EducationTodayIndia.com</b></h1>
                        <p >At EducationTodayIndia.com, we are committed to protecting the privacy and security of our users.
                            This Privacy Policy outlines how we collect, use, and safeguard your personal information when you
                            visit our website.</p>

                        <b><h4 className="text-dark">1. Information We Collect:</h4></b>
                        <p >- Personal Information: When you visit our website, we may collect certain personal information
                            such as your name, email address, and contact details if you choose to provide them voluntarily, for
                            example, when subscribing to our newsletter or filling out a contact form.</p>

                        <p >- Usage Data: We may also collect non-personal information about your browsing activity on our
                            website, including your IP address, browser type, pages visited, and the duration of your visit. This
                            information helps us analyse trends, improve our website, and enhance your user experience.</p>


                        <b><h4 className="text-dark">2. How We Use Your Information :</h4></b>
                        <p > - Personalization: We may use the information collected to personalize your experience on our
                            website, such as providing tailored content, recommendations, and advertisements based on your
                            interests and preferences.
                        </p>

                        <p > - Communication: We may use your contact information to send you newsletters, updates,
                            promotional offers, and other communications related to our products and services. You can opt out
                            of receiving these communications at any time by following the unsubscribe instructions provided in
                            the email.
                        </p>


                        <p >- Analytics: We may use usage data for analytical purposes, such as tracking website traffic,
                            measuring the effectiveness of our marketing campaigns, and improving the performance and
                            functionality of our website.
                        </p>


                        <b><h4 className="text-dark">3. Data Security :</h4></b>
                        <p >- We take appropriate security measures to protect your personal information from unauthorized
                            access, alteration, disclosure, or destruction. These measures include encryption, password
                            protection, and restricted access to your personal data.</p>

                        <p>- However, please note that no method of transmission over the internet or electronic storage is
                            100% secure, and we cannot guarantee the absolute security of your data.</p>


                        <b><h4 className="text-dark">4. Third-Party Links :</h4></b>
                        <p >- Our website may contain links to third-party websites or services that are not owned or controlled
                            by EducationTodayIndia.com. We are not responsible for the privacy practices or content of these
                            third-party sites. We encourage you to review the privacy policies of these websites before providing
                            any personal information.</p>
                        <b><h4 className="text-dark">5. Children&#39;s Privacy :</h4></b>
                        <p >- Our website is not intended for children under the age of 13, and we do not knowingly collect
                            personal information from children. If you are under 13, please do not provide any personal
                            information on our website without parental consent.</p>

                        <b><h4 className="text-dark">Changes to this Privacy Policy :</h4></b>
                        <p >- We may update this Privacy Policy from time to time to reflect changes in our practices or legal
                            requirements. We will notify you of any material changes by posting the updated policy on our
                            website. Your continued use of the website after the changes are made constitutes your acceptance
                            of the revised policy.</p>

                        <b><h4 className="text-dark">7. Contact Us :</h4></b>
                        <p >- If you have any questions or concerns about this Privacy Policy or our privacy practices, please
                            contact us at [support@educationtodayindia.com].</p>

                        <p>By using our website, you consent to the collection, use, and disclosure of your information as
                            described in this Privacy Policy. Thank you for visiting EducationTodayIndia.com.</p>



                    </div>
                </div>
            </div>
            <FooterView />
        </>
    )
}

export default PrivacyPolicy;