import { useEffect, useState } from "react";
import { SchoolRepository } from "../../repository/SchoolRepository";
import { useDispatch, useSelector } from "react-redux";
import { ActionType, AppState } from "../../store/Page.Actions";
import { useNavigate } from "react-router";

export function  DistrictsListViewModel()
{
    const dispatch = useDispatch();
	const navigate = useNavigate();
    const repository =SchoolRepository();
const[lstDistricts,setDistricts]=useState([])
const[selectedState,setSelectedState]=useState({state_name:'',state_id:0})
const stateDetails:any =  

useSelector((state: AppState) =>
    {
      
      
    
      return  typeof(state.storeData.SelectedState)!='undefined'?state.storeData.SelectedState:null;
    }
    );







// useSelector( (state: AppState) =>
// {
//     debugger;
// return (state.storeData.viewName=='SelectedState'?state.storeData.storeData:null);

// });


const districtClick=(district:any)=>
{
    dispatch({
        type: ActionType.STORE,
        payload: {
            storeData:{district_id:district.district_id,district_name:district.district_name},
            viewName:'SelectedDistrict',
        },
      });
      navigate('/citieslist');
}

useEffect(()=>
{
    setSelectedState(stateDetails);
const getDistricts=async ()=>
{
   debugger;
    const data:any = await repository.viewDistricts(stateDetails.state_id);
    setDistricts(data);
    
}
debugger;
if( stateDetails && stateDetails.state_id)
getDistricts();
},[])

return {lstDistricts,selectedState,districtClick};
}