import Header from "../../components/Header/HeaderView";
import FooterView from "../Footer/FooterView";

const OurTeam: React.FC = () => {

    return (
        <>
            <Header />
            <div className="container">
                <div className="row mt-5 mb-5">
                    <div className="col-md-12 school-features">
                        <h1 className="mb-2"><b>Dr. Zeeshan Abbas</b></h1>
                        <h3 className="text-capitalize">Founder and Editor of Education Today Magazine</h3>


                        <p>Dr. Zeeshan Abbas is a visionary educator and leader in the field of education, renowned for his
                            unwavering commitment to promoting lifelong learning and empowering learners of all ages. As the
                            founder and editor of Education Today Magazine since its inception in 2008, Dr. Abbas has played a
                            pivotal role in shaping the publication into a trusted source of insightful and engaging content
                            covering a diverse range of educational topics.</p>


                        <p>With a background in Journalism and Mass Communication and a passion for inspiring others to
                            reach their full potential, Dr. Abbas envisioned Education Today Magazine as a platform to provide
                            valuable resources, practical tips, and inspiring stories to readers of all ages, from kids to youth.
                            Under his leadership, the magazine has grown to become a leading authority in the education
                            sector, offering readers a wealth of knowledge and inspiration to support their educational journey.</p>


                        <p>Dr. Abbas&#39;s dedication to excellence and innovation has been instrumental in driving the success of
                            Education Today Magazine. Through his visionary leadership, he has fostered a culture of creativity,
                            collaboration, and continuous improvement, ensuring that the publication remains at the forefront
                            of educational discourse and meets the evolving needs of its readership.</p>

                        <p> In addition to his role as founder and editor of Education Today Magazine, Dr. Zeeshan Abbas is also
                            actively involved in various educational initiatives and organizations aimed at promoting access to
                            quality education, fostering lifelong learning, and empowering individuals to realize their full
                            potential. His tireless dedication to advancing the cause of education has earned him widespread
                            recognition and respect in the education community.</p>


                        <p > As Education Today Magazine continues to inspire and empower learners around the world, Dr.
                            Zeeshan Abbas remains steadfast in his commitment to making a positive impact in the field of
                            education and shaping the future of learning for generations to come.</p>


<hr />


                        <h1 className="mb-2"><b>R. Srinivas</b></h1>
                        <h3 className="text-capitalize">Chief Operations Officer at Education Today</h3>


                        <p>R. Srinivas is a dynamic and results-driven professional with a wealth of experience in operations
                            management and strategic leadership. As the Operations Manager at Education Today, Srinivas plays
                            a pivotal role in overseeing the day-to-day operations of the organization and ensuring the smooth
                            functioning of all departments.</p>
                        <p>With a keen eye for detail and a proactive approach, Srinivas is responsible for optimizing processes,
                            improving efficiency, and implementing best practices to drive operational excellence across the
                            organization. His strategic mindset and innovative thinking have led to the successful execution of
                            numerous projects and initiatives, contributing to the overall growth and success of Education
                            Today.</p>
                        <p>Srinivas brings to his role a strong background in operations management, having previously held
                            key positions in leading organizations across various industries. His diverse skill set, coupled with his
                            deep understanding of the education sector, uniquely positions him to lead operations at Education
                            Today with precision and effectiveness.</p>
                        <p>Known for his professionalism, integrity, and dedication to achieving results, Srinivas is highly
                            respected by colleagues and peers alike. His collaborative leadership style fosters teamwork and
                            fosters a culture of accountability and continuous improvement within the organization.</p>
                        <p>In addition to his operational responsibilities, Srinivas is also actively involved in strategic planning,
                            resource allocation, and stakeholder management, ensuring that Education Today remains at the
                            forefront of the education sector and continues to deliver value to its readers and stakeholders.</p>
                        <p>With Srinivas at the helm of operations, Education Today is well-positioned to achieve its goals and
                            objectives, driving innovation, growth, and success in the ever-evolving landscape of education.</p>


<hr />

                        <h1 className="mb-2"><b>S. Jawad</b></h1>
                        <h3 className="text-capitalize">Chief Marketing Officer</h3>

                        <p>S. Jawad is a seasoned marketing professional with a passion for driving business growth and
                            building strong, customer-centric brands. As the Chief Marketing Officer at Education Today, Jawad
                            leads the organization&#39;s marketing efforts with a focus on strategic planning, brand development,
                            and customer engagement.</p>
                        <p>With a proven track record of success in the marketing field, Jawad brings a wealth of experience
                            and expertise to his role. He is known for his innovative approach to marketing and his ability to
                            develop and execute comprehensive marketing strategies that drive results.</p>
                        <p>Jawad&#39;s leadership is characterized by his keen understanding of market trends, consumer behavior,
                            and competitive landscape. He leverages this insight to identify opportunities for growth, develop
                            targeted marketing campaigns, and position Education Today as a leader in the education sector.</p>
                        <p>In addition to his strategic acumen, Jawad is a skilled communicator and relationship-builder. He
                            excels at collaborating with cross-functional teams, fostering partnerships with key stakeholders,
                            and engaging with customers to understand their needs and preferences.</p>
                        <p>Under Jawad&#39;s guidance, Education Today&#39;s marketing initiatives are designed to resonate with
                            target audiences, drive brand awareness, and generate leads. Whether it&#39;s through digital marketing
                            channels, traditional advertising methods, or innovative promotional campaigns, Jawad ensures that
                            Education Today&#39;s marketing efforts are effective, impactful, and aligned with the organization&#39;s
                            goals and objectives.</p>
                        <p>With Jawad at the helm of the marketing department, Education Today is well-positioned to
                            enhance its brand presence, expand its reach, and achieve continued success in the competitive
                            landscape of the education industry. His leadership, vision, and dedication to excellence make him a
                            valuable asset to the organization.</p>

                    </div>
                </div>
            </div>
            <FooterView />
        </>
    )
}

export default OurTeam;