import { useNavigate } from "react-router";

const HomeRegistationHelp:React.FC=()=>
{
    const navigate = useNavigate();
const onRegisterClick=()=>
{

    navigate('/schoolregistration');
}

return(<section className="bg-platinum p-0">
<div className="container-fluid">
    <div className="row">
        <div className="col-md-4">
            <img src="img/scl-5.png" className="w-100" style={{marginBottom: 'unset'}}/>
        </div>
        <div className="col-md-7 my-auto ms-5">
            <h2 className="fw-600 mt-3 fs-2em">Enlist Your School</h2>

            <p className="fs-16"><i className="fa-solid fa-right-long f-medium mx-2" ></i>Unlock unparalleled visibility and connect with a vast audience of prospective students and
                parents.</p>
            <p className="fs-16"> <i className="fa-solid fa-right-long  f-medium mx-2" ></i>Encourage audience to discover and experience your school.</p>
            <p className="fs-16"> <i className="fa-solid fa-right-long f-medium mx-2" ></i>Receive support and guidance in the admission process.</p>

            <button className="eti-btn" onClick={onRegisterClick}>
                Register
            </button>
        </div>
    </div>
</div>
</section>);

}
export default HomeRegistationHelp;