import AsyncSelect from "react-select/async";
import { ClustersViewModel } from "./ClustersViewModel";

const ClustersView:React.FC<{ onClusterChange: (cluster_id: number) => void,city_id:number}>=({onClusterChange,city_id})=>
{

    const viewModel=ClustersViewModel(onClusterChange,city_id);

    return (
        <>
           
        <AsyncSelect
        defaultOptions={viewModel.allOptions}
          isClearable
          isSearchable
          value={viewModel.selectedOption}
          onChange={viewModel.handleSelectChange}
          loadOptions={viewModel.loadOptions}
        />
        
        </>
      );

}

export default ClustersView;