import AsyncSelect from "react-select/async";
import Menu from "../Menu/MenuView";
import { HeaderViewModel } from "./HeaderViewModel";

const HeaderView: React.FC = () => {
	const viewModel = HeaderViewModel();

	return (<header id="header" data-plugin-options="{'stickyEnabled': true, 'stickyEnableOnBoxed': true, 'stickyEnableOnMobile': false, 'stickyStartAt': 164, 'stickySetTop': '-164px', 'stickyChangeLogo': false}"
	>
		<div className="header-body border-0 t-0" >

			<div className="header-container container-fluid bg-header">
				<div className="container py-3 " style={{ maxWidth: '1650px' }}>
					<div className="row">
						<div className="col-md-3 order-md-1">
							<a href="/">
								<img alt="Education India Today" className="w-80"
									src="/img/images/logo.png" />
							</a>
						</div>
						<div className="col-md-4 order-md-2 col-0"></div>
						<div className="col-md-5 m-auto text-end order-md-3">

							<h3 className="header-heading_title mb-3 text-center">Explore schools &amp; Colleges  </h3>



							<div className="input-group" >



								<input type="text" className="form-control b-r-13" placeholder="Institution Name..." onChange={(e) => viewModel.onSearchedInstitutionChange(e.target.value)} value={viewModel.searchedInstitution} />
								{viewModel.cities.length > 0 && (<AsyncSelect
									defaultOptions={viewModel.cities}
									isClearable
									isSearchable
									value={viewModel.selectedCity}
									onChange={viewModel.handleCityChange}
									loadOptions={viewModel.loadOptions}
									className="form-control p-0 brd-none brd-0 drp-control "
									placeholder="City..."
								/>
								)}
								{/* <input type="text" className="form-control" placeholder="City"/> */}

								<div className="input-group-prepend" onClick={viewModel.btnSearchClick}>

									<span className="input-group-text prepend-search-btn" >

										<label className="border-0"><i className="fa fa-search"></i></label>

									</span>

								</div>

							</div>

							<p className="text-dark mt-3 mb-0 c-p">
								<span className="mx-5 t-d-u" onClick={viewModel.b2bHome}>Click here if your institution has not set-up the store</span>
								<b className="t-d-n">Login | Cart </b>
							</p>





						</div>
					</div>
				</div>
			</div>


			<Menu />

		</div>

	</header>);
}
export default HeaderView;