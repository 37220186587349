import { useEffect, useState } from "react";
import { SchoolRepository } from "../../repository/SchoolRepository";
import Swal from 'sweetalert2';
import { useNavigate } from "react-router";

export function AdmissionSchoolSearchViewModel() {
    const repository = SchoolRepository();
    const navigate = useNavigate();

    const [activeComponent, setActiveComponent] = useState('Type');

    const [component, setComponent] = useState([
        { 'screen': 'Type', 'Active': 'True' },
        { 'screen': 'Class', 'Active': 'False' },
        { 'screen': 'Fees', 'Active': 'False' },
        { 'screen': 'Board', 'Active': 'False' },
        { 'screen': 'Details', 'Active': 'False' },
        { 'screen': 'Contact', 'Active': 'False' }
    ]);

    const [lstClassCategories, setClassCategories] = useState([
        {
            ClassCategories: '',
            selected: 'False'
        }
    ])

    const [lstClasses, setClasses] = useState([
        {
            classes: "",
            selected: "False"
        }
    ])

    const [lstSelected, setSelected] = useState({
        Type: '',
        Class: '',
        Fees: [] as any,
        Board: [] as any,
    })

    const [lstFees, setFees] = useState([
        { 'Fees': '< 30 K', 'Start': '0', 'End': '30000', 'Selected': 'False' },
        { 'Fees': '30K to 50k', 'Start': '30000', 'End': '50000', 'Selected': 'False' },
        { 'Fees': '50K to 70K', 'Start': '50000', 'End': '70000', 'Selected': 'False' },
        { 'Fees': '70000 to 1Lac', 'Start': '70000', 'End': '100000', 'Selected': 'False' },
        { 'Fees': '1Lac to 2Lac', 'Start': '100000', 'End': '200000', 'Selected': 'False' },
        { 'Fees': '> 2Lac', 'Start': '200000', 'End': '20000000', 'Selected': 'False' },
    ])

    const [lstBoard, setBoard] = useState([
        { 'Board': 'CBSE', 'Selected': 'False' },
        { 'Board': 'ICSE', 'Selected': 'False' },
        // { 'Board': 'IB', 'Selected': 'False' },
        // { 'Board': 'IGCSE', 'Selected': 'False' },
        { 'Board': 'State', 'Selected': 'False' },
        { 'Board': 'Others', 'Selected': 'False' },
    ])

    const [contact, setContact] = useState(
        { full_name: '', email: '', mobile_number: '', location: '', requested: '' }
    )

    const [formSubmit, setFormSubmit] = useState(false);

    const getClassCategories = async () => {
        debugger;
        const data1: any = await repository.getClassCategories('');
        debugger;
        if (data1.length > 0) {
            debugger;
            setClassCategories(data1)
        }
    }

    const viewClasses = async () => {
        debugger;
        let selectClassCategory = lstClassCategories.filter((x: any) => x.selected == 'True');
        const data1: any = await repository.getclassesfromclasscategory({ 'class_category': selectClassCategory[0].ClassCategories });
        debugger;
        if (data1.length > 0) {
            debugger;
            setClasses(data1)
        }
    }

    const selectCaegoryClass = (d: string) => {
        debugger;
        // let lstCategories = {...lstClassCategories};
        let lstCategories = Array.isArray(lstClassCategories) ? lstClassCategories : [];
        debugger;
        for (let i = 0; i < lstCategories.length; i++) {
            debugger;
            if (lstCategories[i].ClassCategories == d) {
                debugger;
                lstCategories[i].selected = 'True';
            } else {
                lstCategories[i].selected = 'False';
            }
        }
        setClassCategories([...lstCategories])
    }

    const selectClass = (d: string) => {
        debugger;
        let lstClass = Array.isArray(lstClasses) ? lstClasses : [];
        for (let i = 0; i < lstClass.length; i++) {
            debugger;
            if (lstClass[i].classes == d) {
                debugger;
                lstClass[i].selected = 'True';
            } else {
                lstClass[i].selected = 'False';
            }
        }
        setClasses([...lstClass])
    }

    const prev = () => {
        debugger;
        let active = activeComponent;
        let newScreen = '';
        for (let i = 0; i < component.length; i++) {
            debugger;
            if (component[i].screen == active && active != 'Type') {
                debugger;
                newScreen = component[i - 1].screen;
            }
        }
        setActiveComponent(newScreen);
    }

    const next = () => {
        debugger;
        let active = activeComponent;
        let newScreen = '';
        for (let i = 0; i < component.length; i++) {
            debugger;
            if (component[i].screen == active) {
                debugger;
                newScreen = component[i + 1].screen;
            }
        }
        let selection = { ...lstSelected }
        if (active == 'Type') {
            debugger;
            let selectedSchoolCategory = lstClassCategories.filter((x: any) => x.selected == 'True');
            selection.Type = selectedSchoolCategory[0].ClassCategories
            viewClasses()
        }
        if (active == 'Class') {
            debugger;
            let selectedClass = lstClasses.filter((x: any) => x.selected == 'True');
            selection.Class = selectedClass[0].classes
            viewClasses()
        }

        setSelected(selection)
        setActiveComponent(newScreen);
    }

    const selectFees = (d: any, index: number) => {
        debugger;
        let fees = { ...lstSelected };
        if (fees.Fees.length == 0) {
            debugger;
            fees.Fees.push(d);
        } else {
            debugger;
            let lstFee = fees.Fees.filter((x: any) => x.Fees == d.Fees);
            debugger;
            if (lstFee.length == 0) {
                debugger;
                fees.Fees.push(d);
            } else {
                debugger;
                let lstFee = fees.Fees.findIndex((x: any) => x.Fees == d.Fees);
                fees.Fees.splice(lstFee, 1);
            }
        }

        let lstfee = Array.isArray(lstFees) ? lstFees : [];
        for (let i = 0; i < lstfee.length; i++) {
            debugger;
            if (i == index) {
                debugger;
                lstfee[i].Selected = (lstfee[i].Selected == 'False' ? 'True' : 'False');
            }
        }
        debugger;
        setFees(lstfee);
        setSelected(fees);
    }

    const selectBoard = (d: any, index: number) => {
        debugger;
        let board = { ...lstSelected };
        if (board.Board.length == 0) {
            debugger;
            board.Board.push(d);
        } else {
            debugger;
            let lstBoard = board.Board.filter((x: any) => x.Board == d.Board);
            debugger;
            if (lstBoard.length == 0) {
                debugger;
                board.Board.push(d);
            } else {
                debugger;
                let lstBoard = board.Board.findIndex((x: any) => x.Board == d.Board);
                board.Board.splice(lstBoard, 1);
            }
        }

        let lst_Board = Array.isArray(lstBoard) ? lstBoard : [];
        for (let i = 0; i < lst_Board.length; i++) {
            debugger;
            if (i == index) {
                debugger;
                lst_Board[i].Selected = (lst_Board[i].Selected == 'False' ? 'True' : 'False');
            }
        }
        debugger;
        setBoard(lst_Board);
        setSelected(board);
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        debugger;
        if ('value' in e.target) {
            debugger;
            const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;
            setContact((prev) => ({ ...prev, [name]: value }));
        } else {
            debugger;
            const { name, value } = e.target as HTMLSelectElement;
            setContact((prev) => ({ ...prev, [name]: value }));
        }
    }

    const submit = async () => {
        debugger;
        setFormSubmit(true);

        if (contact.full_name != '' && contact.email != '' && contact.mobile_number != '' && contact.location != '') {
            debugger;
            contact.requested = JSON.stringify(lstSelected);
            const responseData = await repository.saveAdmissionHelp(contact);
            debugger;
            if (responseData[0].db_result > 0) {
                Swal.fire({
                    title: 'Success',
                    text: 'Enquiry sent successfully!',
                    icon: 'success',
                    confirmButtonText: 'Ok',
                }).then((input: any) => {
                    debugger;
                    navigate('/schools' , { state: lstSelected ?? [] });
                });
            }
        }
    }

    useEffect(() => {
        debugger;
        getClassCategories();
    }, [alert]);

    return {
        lstClassCategories, selectCaegoryClass, activeComponent, next, prev, lstClasses, selectClass,
        lstSelected, lstFees, selectFees, lstBoard, selectBoard, submit, contact, handleChange, formSubmit
    }
}