import HomeRegistationHelp from "../../components/HomeRegistationHelp"
import Header from "../../components/Header/HeaderView";
import Footer from "../../components/Footer/FooterView";

import AdmissionHelpMenuView from "../../components/AdmissionHelpMenu/AdmissionHelpMenuView";
const AdmissionHelpHomeView:React.FC=()=>
{



return (
<>
<Header/>
<AdmissionHelpMenuView/>
<HomeRegistationHelp/>

<Footer/>
</>
)

}
export default AdmissionHelpHomeView