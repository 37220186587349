import { useEffect, useState } from "react";
import { SchoolRepository } from "../../repository/SchoolRepository";
import { useDispatch, useSelector } from "react-redux";
import { ActionType, AppState } from "../../store/Page.Actions";
import { useNavigate } from "react-router";

export function  CitiesListViewModel()
{
    const dispatch = useDispatch();
	const navigate = useNavigate();
    const repository =SchoolRepository();
const[lstCities,setCities]=useState([])
const[selectedDistrict,setSelectedDistrict]=useState({district_name:'',district_id:0})
const districtDetails:any =    useSelector( (district: AppState) =>
{
    debugger;
return (district.storeData.viewName=='SelectedDistrict'?district.storeData.storeData:null);

});

const cityClick=(city:any)=>
{

    debugger;
    dispatch({
        type: ActionType.STORE,
        payload: {
            storeData:{city_id:city.city_id,city_name:city.city_name},
            viewName:'SelectedCity',
        },
      });
      navigate('/clusterslist');
}


useEffect(()=>
{
    setSelectedDistrict(districtDetails);
const getCities=async ()=>
{
   debugger;
    const data:any = await repository.viewCities(districtDetails.district_id);
    setCities(data);
    
}


getCities();
},[])

return {lstCities,selectedDistrict,cityClick};
}

 
