import { AdmissionHelpMenuViewModel } from "./AdmissionHelpMenuViewModel";

 
const AdmissionHelpMenuView:React.FC=()=>
{

const viewModel=AdmissionHelpMenuViewModel();

    return (

        <div className="container-fluid drd-bg-success p-0">
        
        <div className="row">
        
            <div className="col-md-12">
        
                <ul className="d-flex ls-none pointer-link mt-3 offset-md-4 p-0 align-items-baseline">
        
                    <li className="mx-3 text-light" onClick={()=>viewModel.listingClick()} >Listing</li>
        
                    <li className="mx-3 text-light" onClick={()=>viewModel.admissionHelp()}>Admission Navigator</li>
        
                    <li className="mx-3 text-light">Digital Map</li>
                    {/* <li className="mx-3 text-light p-2 orange"><span className="badge bg-red px-2 py-0 mt-1"><a className="text-light nav-link" href="/schoolregistration"><big className="mr-1">✚</big> <big>List School</big></a>
                    </span></li> */}
        
                </ul>
        
            </div>
        
        </div>
        
        </div>
        
            );




}
 export  default AdmissionHelpMenuView;