import { useEffect, useState } from "react";
import { SchoolRepository } from "../../repository/SchoolRepository";
import { useDispatch, useSelector } from "react-redux";
import { ActionType, AppState } from "../../store/Page.Actions";
import { useNavigate } from "react-router";

export function  ClustersListViewModel()
{
    const dispatch = useDispatch();
	const navigate = useNavigate();
    const repository =SchoolRepository();
const[lstClusters,setClusters]=useState([])
const[selectedCity,setSelectedCity]=useState({city_name:'',city_id:0})
const cityDetails:any =    useSelector( (city: AppState) =>
{
    debugger;
return (city.storeData.viewName=='SelectedCity'?city.storeData.storeData:null);

});

const clusterClick=(cluster:any)=>
{
    let sDetails:any={cluster_id:cluster.cluster_id,search_string:''};
     
 
    dispatch({
        type: ActionType.STORE,
        payload: {
            storeData:sDetails,
            viewName:'SearchSchool',
        },
      });

    navigate('/schools');
}


useEffect(()=>
{
    setSelectedCity(cityDetails);
const getClusters=async ()=>
{
   debugger;
    const data:any = await repository.viewClusters(cityDetails.city_id);
    setClusters(data);
    
}


getClusters();
},[])

return {lstClusters,selectedCity,clusterClick};
}

 
