import { useEffect, useState } from "react";
import { SchoolRepository } from "../../repository/SchoolRepository";

export function EduNewsViewModel()
{
    const repository=SchoolRepository();
    const [eduFeedItems, setEduFeedItems] = useState<Array<any>>([]);
    const openFeedPage = (url:any) => {
     
        window.open(url, '_blank');
           };
    useEffect(() => {

        // return()=>
        // {
            debugger;
            const getEduFeeds = async () => {
                debugger;
                 
           const data:any = await repository.fetchRssFeed();
       
let datas= JSON.parse( data);
          // /let res:[]=JSON.parse( data).item;
          if (!Array.isArray(datas)) {

            setEduFeedItems([JSON.parse( data)]);
          debugger;
          }else{
            setEduFeedItems(JSON.parse( data));
          }
                 
          
             
              
        }
        getEduFeeds();
   // }
    
    }, []);

    return {eduFeedItems,openFeedPage};
}