import Header from "../../components/Header/HeaderView";
import FooterView from "../Footer/FooterView";

const EduEvents: React.FC = () => {

    return (
        <>
            <Header />
            <div className="container">
                <div className="row mt-5 mb-5">
                    <div className="col-md-12 school-features">
                        <h1><b>Edu-Events</b></h1>
                        <p>Welcome to Edu-Events, your premier destination for organizing a wide range of educational events
                            tailored to meet the diverse needs of schools, colleges, educational institutions, and organizations.
                            At Edu-Events, we specialize in planning and executing seminars, webinars, edu-fairs and exhibitions,
                            conferences and award ceremonies, as well as educational trips and excursions, to enrich the
                            learning experience and promote academic excellence.</p>



                        <b><h4 className="text-dark">Seminars :</h4></b>
                        <p>Edu-Events offers expertly organized seminars that provide valuable insights, knowledge sharing,
                            and networking opportunities for educators, administrators, and students. Our seminars cover a
                            variety of topics, including educational trends, teaching strategies, curriculum development, and
                            more, delivered by renowned speakers and industry experts.</p>

                        <b><h4 className="text-dark">Webinars :</h4></b>
                        <p>Experience the convenience of virtual learning with our engaging webinars hosted by leading
                            professionals and thought leaders in the education sector. Whether it&#39;s professional development
                            sessions for teachers, interactive workshops for students, or informative discussions on educational
                            topics, our webinars offer a flexible and accessible way to learn and grow.</p>


                        <b><h4 className="text-dark">Edu-Fairs and Exhibitions :</h4></b>
                        <p>Edu-Events organizes vibrant edu-fairs and exhibitions that bring together educational institutions,
                            publishers, vendors, and stakeholders under one roof. These events offer a platform for showcasing
                            innovative products, services, and educational resources, as well as networking opportunities and
                            access to the latest trends and developments in the education industry.</p>


                        <b><h4 className="text-dark">Conferences and Award Ceremonies :</h4></b>
                        <p>Celebrate excellence and innovation in education with our meticulously planned conferences and
                            award ceremonies. Whether it&#39;s recognizing outstanding educators, students, or institutions, our
                            events honor achievements and contributions in the field of education while providing a forum for
                            sharing best practices, ideas, and inspiration.</p>


                        <b><h4 className="text-dark">Edu-Trips and Excursions :</h4></b>
                        <p>Embark on educational adventures with our carefully curated edu-trips and excursions designed to
                            enhance learning outside the classroom. From cultural heritage tours to scientific expeditions, our
                            trips offer students immersive experiences, hands-on learning opportunities, and memorable
                            encounters that enrich their academic and personal growth.</p>

                        <p>At Edu-Events, we are committed to delivering exceptional events that inspire, educate, and
                            empower participants to reach their full potential. Whether you&#39;re looking to organize a seminar,
                            webinar, edu-fair, conference, award ceremony, or edu-trip, our experienced team will work closely
                            with you to create a customized event that exceeds your expectations and achieves your goals.
                            Contact us today to bring your educational event to life with Edu-Events!</p>



                    </div>
                </div>
            </div>
            <FooterView />
        </>
    )
}

export default EduEvents;