import { useNavigate } from "react-router";
import { SchoolRepository } from "../../repository/SchoolRepository";
import { useEffect, useState } from "react";

export function EduNewsViewModel() {


  const repository = SchoolRepository();
  const navigate = useNavigate();
  const [rssFeeds, setFeedItems] = useState<Array<any>>([]);

  const [loadjs, setjs] = useState<Boolean>(false);
  const [isJqueryLoaded, setJqueryLoaded] = useState<Boolean>(false);

  const openFeedPage = (url: any) => {
    debugger;
    window.open(url, '_blank');
  };
  const OpenEduNews = () => {
    navigate('/edunews');
  }

  useEffect(() => {

    // /return()=>
    {
      setjs(false);

      const fetchRssFeed = async () => {
        debugger
        const data: any = await repository.fetchRssFeed();
        debugger

        let datas = JSON.parse(data);
        if (!Array.isArray(datas)) {

          setFeedItems([JSON.parse(data)]);
          debugger;
        } else {
          setFeedItems(JSON.parse(data));
        }


        if (!isJqueryLoaded) {
          loadScripts();

        }
        setjs(true)

      };

      fetchRssFeed();
    }
  }, []);



  const loadScripts = () => {
    const script1 = document.createElement('script');
    script1.src = 'https://code.jquery.com/jquery-3.4.1.min.js';
    script1.async = true;
    document.body.appendChild(script1);

    script1.onload = () => {
      debugger;
      const script2 = document.createElement('script');
      script2.src = 'https://cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.3.4/owl.carousel.min.js'; // Replace with the correct local path or CDN
      script2.async = true;
      document.body.appendChild(script2);

      script2.onload = () => {
        debugger;         // Initialize Owl Carousel here
        var $ = jQuery.noConflict();
        try {

          $(document).ready(function () {
            debugger;


            ($(".gallery-carousel") as any).owlCarousel({
              loop: true,
              autoplay: true,
              margin: 10,
              dots: false,
              nav: false,
              center: true,
              responsive: {
                0: {
                  items: 1,
                },
                600: {
                  items: 1,
                },
                1000: {
                  items: 3,
                },
              },
            });
            setJqueryLoaded(true);



          });

        } catch (e) {


        }
        $(".prev-btn").click(() => {
          debugger
          $(".gallery-carousel").trigger("prev.owl.carousel");
        });

        $(".next-btn").click(() => {
          $(".gallery-carousel").trigger("next.owl.carousel");
        });

      };
    };
  };





  return { rssFeeds, openFeedPage, OpenEduNews }
}