
import Footer from "../../components/Footer/FooterView";
import Header from "../../components/Header/HeaderView";
import { SchoolListViewModel } from "./SchoolListViewModel";
const SchoolListView: React.FC = () => {
  const viewModel = SchoolListViewModel();
  return (
    <><Header />
      <section className="bg-gray p-3">
        <div className="container">
          <div className="row">

            <div className="col-md-3">

              <div className="accordion bx-shadow" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      <b>Classification</b>
                    </button>
                  </h2>
                  <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="Co-educational" onChange={(e) => viewModel.classificationSelectionValueChange(e.target.checked, 'Co-educational')} />
                            <label className="form-check-label" >
                              Co-Ed
                            </label>
                          </div>
                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="Girls Only" onChange={(e) => viewModel.classificationSelectionValueChange(e.target.checked, 'Girls Only')} />
                            <label className="form-check-label">
                              Girls
                            </label>
                          </div>

                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="Boys Only" onChange={(e) => viewModel.classificationSelectionValueChange(e.target.checked, 'Boys Only')} />
                            <label className="form-check-label" >
                              Boys
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      <b>Board</b>
                    </button>
                  </h2>
                  <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="CBSE" onChange={(e) => viewModel.boardSelectionValueChange(e.target.checked, 'CBSE')} />
                            <label className="form-check-label" >
                              CBSE
                            </label>
                          </div>
                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="ICSE" onChange={(e) => viewModel.boardSelectionValueChange(e.target.checked, 'ICSE')} />
                            <label className="form-check-label" >
                              ICSE
                            </label>
                          </div>

                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="State" onChange={(e) => viewModel.boardSelectionValueChange(e.target.checked, 'State')} />
                            <label className="form-check-label" >
                              State
                            </label>
                          </div>



                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="Others" onChange={(e) => viewModel.boardSelectionValueChange(e.target.checked, 'Others')} />
                            <label className="form-check-label" >
                              Others
                            </label>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFee">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFee" aria-expanded="false" aria-controls="collapseTwo">
                      <b>Fees</b>
                    </button>
                  </h2>
                  <div id="collapseFee" className="accordion-collapse collapse" aria-labelledby="headingFee" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <div className="row">
                        {
                          viewModel.lstFees && viewModel.lstFees.map((d: any, index: number) => (
                            <div className="col-md-12">
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="CBSE" onChange={(e) => viewModel.feeSelection(d)} />
                                <label className="form-check-label" >
                                  {d.Fees}
                                </label>
                              </div>
                            </div>
                          ))
                        }

                      </div>
                    </div>
                  </div>
                </div>


                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFour">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
                      <b>Classes</b>
                    </button>
                  </h2>
                  <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-check">

                            <input className="form-check-input" type="checkbox" value="Pre-Primary Only" onChange={(e) => viewModel.classSelectionValueChange(e.target.checked, 'Pre-Primary Only')} />
                            <label className="form-check-label" >
                              Pre-Primary Only
                            </label>
                          </div>

                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="Pre-Primary to Primary" onChange={(e) => viewModel.classSelectionValueChange(e.target.checked, 'Pre-Primary to Primary')} />
                            <label className="form-check-label" >
                              Pre-Primary to Primary
                            </label>
                          </div>


                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="Pre-Primary To 4" onChange={(e) => viewModel.classSelectionValueChange(e.target.checked, 'Pre-Primary To 4')} />
                            <label className="form-check-label" >
                              Pre-Primary To 4
                            </label>
                          </div>


                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="Primary To 4" onChange={(e) => viewModel.classSelectionValueChange(e.target.checked, 'Primary To 4')} />
                            <label className="form-check-label" >
                              Primary To 4
                            </label>
                          </div>




                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="1 To 4" onChange={(e) => viewModel.classSelectionValueChange(e.target.checked, '1 To 4')} />
                            <label className="form-check-label" >
                              1 To 4
                            </label>
                          </div>
                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="Pre-Primary To 7" onChange={(e) => viewModel.classSelectionValueChange(e.target.checked, 'Pre-Primary To 7')} />
                            <label className="form-check-label" >
                              Pre-Primary To 7
                            </label>
                          </div>

                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="Primary To 7" onChange={(e) => viewModel.classSelectionValueChange(e.target.checked, 'Primary To 7')} />
                            <label className="form-check-label" >
                              Primary To 7
                            </label>
                          </div>
                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="1 To 7" onChange={(e) => viewModel.classSelectionValueChange(e.target.checked, '1 To 7')} />
                            <label className="form-check-label" >
                              1 To 7
                            </label>
                          </div>

                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="Pre-Primary To 10" onChange={(e) => viewModel.classSelectionValueChange(e.target.checked, 'Pre-Primary To 10')} />
                            <label className="form-check-label" >
                              Pre-Primary To 10
                            </label>
                          </div>




                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="Primary To 10" onChange={(e) => viewModel.classSelectionValueChange(e.target.checked, 'Primary To 10')} />
                            <label className="form-check-label" >
                              Primary To 10
                            </label>
                          </div>



                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="1 To 10" onChange={(e) => viewModel.classSelectionValueChange(e.target.checked, '1 To 10')} />
                            <label className="form-check-label" >
                              1 To 10
                            </label>
                          </div>



                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="Pre-Primary To 12" onChange={(e) => viewModel.classSelectionValueChange(e.target.checked, 'Pre-Primary To 12')} />
                            <label className="form-check-label" >
                              Pre-Primary To 12
                            </label>
                          </div>




                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="Primary To 12" onChange={(e) => viewModel.classSelectionValueChange(e.target.checked, 'Primary To 12')} />
                            <label className="form-check-label" >
                              Primary To 12
                            </label>
                          </div>

                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="1 To 12" onChange={(e) => viewModel.classSelectionValueChange(e.target.checked, '1 To 12')} />
                            <label className="form-check-label" >
                              1 To 12
                            </label>
                          </div>



                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="8 To 10" onChange={(e) => viewModel.classSelectionValueChange(e.target.checked, '8 To 10')} />
                            <label className="form-check-label" >
                              8 To 10
                            </label>
                          </div>



                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="8 To 12" onChange={(e) => viewModel.classSelectionValueChange(e.target.checked, '8 To 12')} />
                            <label className="form-check-label" >
                              8 To 12
                            </label>
                          </div>





                        </div>
                      </div>
                    </div>
                  </div>
                </div>



                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      <b>Management</b>
                    </button>
                  </h2>
                  <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="Pvt. Unaided" onChange={(e) => viewModel.managementSelectionValueChange(e.target.checked, 'Pvt. Unaided')} />
                            <label className="form-check-label" >
                              Pvt. Unaided
                            </label>
                          </div>


                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="Aided" onChange={(e) => viewModel.managementSelectionValueChange(e.target.checked, 'Aided')} />
                            <label className="form-check-label" >
                              Aided
                            </label>
                          </div>


                        </div>
                      </div>
                    </div>
                  </div>
                </div>




                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFive">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseThree">
                      <b>Facilities</b>
                    </button>
                  </h2>
                  <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="Yes" onChange={(e) => viewModel.facilitiesSelectionValueChange(e.target.checked, 'Computer Aided Learning')} />
                            <label className="form-check-label" >
                              Computer Aided Learning
                            </label>
                          </div>
                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="Yes" onChange={(e) => viewModel.facilitiesSelectionValueChange(e.target.checked, 'Library')} />
                            <label className="form-check-label" >
                              Library
                            </label>
                          </div>

                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="Yes" onChange={(e) => viewModel.facilitiesSelectionValueChange(e.target.checked, 'Playground')} />
                            <label className="form-check-label" >
                              Playground
                            </label>
                          </div>

                          <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="Yes" onChange={(e) => viewModel.facilitiesSelectionValueChange(e.target.checked, 'Transport Facility')} />
                            <label className="form-check-label" >
                              Transport Facility
                            </label>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div className="col-md-9">
              <div className="row">
                <div className="col-md-12">
                  <p><b className="text-danger">{viewModel.lstfilteredSchools.length} Results found</b></p>
                </div>

                {
                  viewModel.lstfilteredSchools.map((school, i) =>
                  (

                    <div className="col-md-12" key={i}>
                      <div className="card bx-shadow p-3">
                        <div className="row">
                          <div className="col-md-3">
                            <img src=

                              {`${viewModel.imagePath}${school.image}.webp?${new Date().getTime()}`}

                              alt="" className="w-100 scl-img-lst" />
                          </div>

                          <div className="col-md-9">
                            <div className="row">
                              <div className="col-md-12 mt-3">
                                <h3 className="text-capitalize fw-500 mb-3">{school.school_name} <i className="fa fa-heart-o float-end f-20 text-danger" aria-hidden="true"></i></h3>
                              </div>

                              {(typeof (school.address) != 'undefined' && school.address != '') ? (<div className="col-md-12">
                                <p className="text-dark"><i className="fa fa-map-marker text-danger" aria-hidden="true"></i> {school.address}</p>
                              </div>) : ''}


                              <div className="col-md-12">
                                <h4><span className="text-warning">N/A</span>
                                  <i className="fa fa-star text-warning" aria-hidden="true"></i>
                                  <i className="fa fa-star text-warning" aria-hidden="true"></i>
                                  <i className="fa fa-star-o text-warning" aria-hidden="true"></i>
                                  <i className="fa fa-star-o text-warning" aria-hidden="true"></i>
                                  <i className="fa fa-star-o text-warning" aria-hidden="true"></i>
                                  <span className="fw-100 f-15">(0 vote)</span>
                                </h4>
                              </div>

                              <div className="col-md-3 col-6 ">
                                <p className="m-0"><i className="fa fa-school cst-dark f-22" aria-hidden="true"></i></p>
                                <p className="mb-0 ">Instruction</p>
                                <p className="m-0 fw-700 text-dark">{school.instruction_medium}</p>
                              </div>

                              <div className="col-md-3 col-6">
                                <p className="m-0"><i className="fa fa-graduation-cap cst-dark f-22" aria-hidden="true"></i></p>
                                <p className="mb-0 ">Board</p>
                                <p className="m-0 fw-700 text-dark">{school.board_for_class_10th}</p>
                              </div>


                              <div className="col-md-3 col-6">
                                <p className="m-0"><i className="fa fa-flag cst-dark f-22" aria-hidden="true"></i></p>
                                <p className="mb-0 ">Gender</p>
                                <p className="m-0 fw-700 text-dark">{school.school_type}</p>
                              </div>

                              <div className="col-md-3 col-6">
                                <p className="m-0"><i className="fa fa-book cst-dark f-22" aria-hidden="true"></i></p>
                                <p className="mb-0 ">Grade</p>
                                <p className="m-0 fw-700 text-dark">{school.classes}</p>
                              </div>
                              <div className="col-md-12 text-end my-auto">
                                <button className="btn btn-outline-danger brd-50" onClick={() => viewModel.btnViewSchoolClick(school)}>View School</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  )
                  )
                }



              </div>
            </div>


          </div>
        </div>
      </section>
      <Footer />
    </>
  )


}

export default SchoolListView;