import { useNavigate } from "react-router";
import { SchoolRepository } from "../../repository/SchoolRepository";

export function B2BHomeModel() {
    const repository = SchoolRepository();
    const navigate = useNavigate();


const onRegisterClick=()=>
{
debugger;
    navigate('/schoolregistration');
}

const pricingClick=()=>
{
    navigate('/pricing');
}

    return {onRegisterClick,pricingClick}
}